import { MenuItem } from '@xq/ui-kit';
import { OrganizationName } from 'interfaces';
import { SidemenuLayoutLicense } from 'layouts/SidemenuLayout/sidemenu-layout-service';
import React from 'react';

export interface SidemenuContextData {
  activeMenu: string;
  setActiveMenu: (value: string) => void;
  sidemenuItems: MenuItem[];
  setSidemenuItems?: (value: MenuItem[]) => void;
  licenses?: SidemenuLayoutLicense[];
  setLicenses?: (value: SidemenuLayoutLicense[]) => void;
  currentOrganization?: OrganizationName;
  setCurrentOrganization?: (value: OrganizationName) => void;
  organizations?: OrganizationName[];
  setOrganizations?: (value: OrganizationName[]) => void;
  isSidemenuLoaded?: boolean;
  setIsSidemenuLoaded: (value: boolean) => void;
}

const sidemenuContextData: SidemenuContextData = {
  activeMenu: '',
  setActiveMenu: () => '',
  sidemenuItems: [],
  setSidemenuItems: () => [],
  licenses: [],
  setLicenses: () => [],
  currentOrganization: null,
  setCurrentOrganization: () => null,
  organizations: [],
  setOrganizations: () => [],
  isSidemenuLoaded: false,
  setIsSidemenuLoaded: () => null
};

export const SidemenuContext =
  React.createContext<SidemenuContextData>(sidemenuContextData);
