import {
  cellStyles,
  Checkbox,
  ComponentProps,
  Icon,
  Radio,
  Toggle,
  Typography
} from '@components';
import {
  SortDirection,
  TableColumn,
  TableDataType,
  TextAlignments
} from '@components/Table/dataTypes';
import { colors } from '@componentsStyles';
import classNames from 'classnames';
import cn from 'classnames';
import CSS from 'csstype';
import React, { FC, useState } from 'react';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps extends ComponentProps {
  columns: TableColumn[];
  actionColumn?: TableColumn;
  onTableDataType?: (value: string) => void;
  onSelectGroupKPI?: (state: boolean) => void;
  sortBy?: string;
  sortOrder?: SortDirection;
  tableDataType?: TableDataType;
  onSort?: (key: string) => void;
  isScrollable?: boolean;
  selectedColumnKey?: string;
  // displayPlusColumn?: boolean;
}

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    columns,
    className,
    actionColumn,
    tableDataType,
    onTableDataType,
    onSelectGroupKPI,
    // displayPlusColumn,
    sortBy,
    onSort,
    sortOrder = 'ASC',
    isScrollable,
    id,
    style,
    selectedColumnKey
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<string>('absolute');
  const [selectGroupKPI, setSelectGroupKPI] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const headCellStyle = (column: TableColumn) => {
    const styles: CSS.Properties = {};

    if (column.alignment === 'right') {
      styles.justifyContent = 'end';
      if (column.sortable) {
        styles.marginLeft = '26px';
      }
      if (column.subtitle) {
        styles.alignItems = 'end';
      }
    }
    if (column.alignment === 'left') {
      styles.justifyContent = 'start';
      if (column.subtitle) {
        styles.alignItems = 'start';
      }
    }
    if (column.alignment === 'center') {
      styles.justifyContent = 'center';
      if (column.subtitle) {
        styles.alignItems = 'center';
      }
    }

    return styles;
  };

  const handleRadioSelect = (value: string) => {
    setSelectedMethod(value);
    onTableDataType(value);
  };

  const handleCheckboxChange = (state: boolean) => {
    setSelectGroupKPI(state);
    onSelectGroupKPI && onSelectGroupKPI(state);
  };

  const getSubtitleStyle = (align: TextAlignments) => {
    const styles: CSS.Properties = {};

    switch (align) {
      case 'start':
        styles.alignSelf = 'start';
        break;

      case 'center':
        styles.alignSelf = 'center';
        break;

      case 'end':
        styles.alignSelf = 'end';
        break;

      default:
        styles.alignSelf = 'start';
    }

    return styles;
  };

  return (
    <thead id={id} style={style} className={className}>
      <tr className={styles.row}>
        {columns?.map((column, key) => {
          return (
            <React.Fragment key={column.key + '_fragment'}>
              <th
                className={styles.cell}
                style={cellStyles({ column, isScrollable, isHeader: true })}
                key={column.key}
                onClick={
                  column.sortable && onSort ? () => onSort(column.key) : null
                }
              >
                <Typography
                  id={`table-header-cell-name-${key}`}
                  className={classNames(styles['cell-name'], {
                    [styles['cell-name--sortable']]: column.sortable,
                    [styles['cell-name--subtitle']]: column.subtitle
                  })}
                  element="div"
                  color={colors.gray40Color}
                  style={headCellStyle(column)}
                  variant="system-heading"
                >
                  {column.title}
                  {column.sortable && (
                    <Icon
                      size="s"
                      className={
                        column.key === sortBy
                          ? styles.sort
                          : styles['sort--hidden']
                      }
                      name={sortOrder === 'DESC' ? 'sort-more' : 'sort-less'}
                    />
                  )}
                  {column.toggle && (
                    <div
                      key={column.toggle.key}
                      className={styles.toggleWrapper}
                    >
                      <Toggle
                        id={`table-header-toggle-${key}`}
                        className={cn(
                          styles['toggleWrapper--toggle'],
                          column?.toggle?.className
                        )}
                        checked={selectedColumnKey === column.key}
                        onChange={() => column.toggle.onToggle(column.key)}
                        disabled={column.toggle.disabled}
                      />
                      <Typography
                        className={styles['toggleWrapper--info']}
                        element="div"
                        color={colors.gray40Color}
                        style={headCellStyle(column)}
                        variant="system-heading"
                      >
                        {column.toggle.title}
                        <div className={styles['toggleWrapper--unit']}>
                          {column.toggle.unit}
                        </div>
                      </Typography>
                    </div>
                  )}
                  {column.subtitle && !column.toggle && (
                    <div
                      className={styles['toggleWrapper--unit']}
                      style={getSubtitleStyle(column.subtitleAlign)}
                    >
                      {column.subtitle}
                    </div>
                  )}
                </Typography>
              </th>
              {/* {displayPlusColumn && key === 0 && (
                <th
                  className={styles.cell}
                  style={cellStyles({
                    column: { ...column, fixed: false },
                    isScrollable,
                    isHeader: true
                  })}
                  key={column.key + '_select'}
                />
              )} */}
            </React.Fragment>
          );
        })}

        {onTableDataType && (
          <th
            className={cn(styles.cell, styles.action)}
            style={cellStyles({ isHeader: true, column: actionColumn })}
          >
            <div className={styles.actionWrapper}>
              {isOpen && (
                <div className={styles.modal}>
                  <div className={styles.modalContent}>
                    <div>
                      <p className={styles.title}>Comparison method</p>
                      <Radio
                        onChange={handleRadioSelect}
                        selected={selectedMethod}
                        className={styles.modalActions}
                        align="vertical"
                        values={[
                          {
                            label: 'Percent',
                            value: 'comparative'
                          },
                          {
                            label: 'Absolute number',
                            value: 'absolute'
                          },
                          {
                            label: 'Hide comparison',
                            value: 'hide_comparison'
                          }
                        ]}
                      />
                    </div>
                    <div>
                      <p className={styles.title}>Settings</p>
                      <Checkbox
                        className={styles.modalActions}
                        label="Group KPI"
                        checked={selectGroupKPI}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                id={'table-header-icon-number'}
                className={styles['dataTypeBtn-wrapper']}
                onClick={handleClick}
              >
                <div
                  className={cn(styles.dataTypeBtn, {
                    [styles['dataTypeBtn-active']]: isOpen
                  })}
                >
                  <Icon
                    size="s"
                    className={styles[`dataTypeBtn-icon`]}
                    name="slider"
                  />
                </div>
              </div>
            </div>
          </th>
        )}
        {actionColumn && (
          <th
            className={cn(styles.cell, styles.action)}
            style={cellStyles({ isHeader: true, column: actionColumn })}
          />
        )}
      </tr>
    </thead>
  );
};

TableHeader.displayName = 'TableHeader';
