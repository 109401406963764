import { Col, EmptyState, Row, Typography } from '@xq/ui-kit';
import React, { FC, Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ORGANIZATION_SIDEMENUS, visualTourTimeout } from '@services';
import { getRouteUrl, ROUTES } from '@router';
import {
  SidemenuContext,
  SidemenuContextData,
  useVisualTourContext
} from '@context';

export const Licenses: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    if (sidemenuContext?.licenses?.length > 0) {
      navigate(
        getRouteUrl(ROUTES.LICENSES.LICENSE, {
          licenseId: sidemenuContext.licenses[0].uuid
        })
      );
    }
  }, [sidemenuContext?.licenses]);

  /** Sidemenu context */
  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_LICENSES);
  }, [sidemenuContext]);

  const {
    setCurrentVisualTourState,
    currentVisualTourState: { tourActive, steps }
  } = useVisualTourContext();

  useEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        if (
          sidemenuContext?.isSidemenuLoaded &&
          sidemenuContext?.licenses &&
          sidemenuContext?.licenses?.length === 0
        ) {
          const updatedSteps = steps?.filter(
            (el) =>
              el.id !== 'LICENSE_PAGE_INACTIVE_USERS_STEP' &&
              el.id !== 'LICENSE_PAGE_ACTIVE_USERS_STEP'
          );

          setCurrentVisualTourState({ steps: updatedSteps });
        }
        setCurrentVisualTourState({ run: true });
      }, visualTourTimeout);
    }
  }, [
    tourActive,
    sidemenuContext?.licenses,
    sidemenuContext?.isSidemenuLoaded
  ]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className="heading" id={'licences-review-visual-tour'}>
          <Typography element="div" variant="h2">
            {t('common.licenses')}
          </Typography>
        </Col>

        {sidemenuContext?.licenses?.length === 0 && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeTheOrganizationHasNoLicenses')}
            isCentered={true}
          />
        )}
      </Row>
    </Fragment>
  );
};

Licenses.displayName = 'Licenses';
