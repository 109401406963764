import { FormEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
): F => {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args: Parameters<F>) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as F;
};

export const checkNoMoreData = (
  /** number of items in response from backend */
  itemsLength: number,
  /** number of items in metadata from backend */
  totalItems: number,
  setNoMoreData: (value: boolean) => void
) => {
  const isNoMoreData = itemsLength === totalItems || itemsLength === 0;
  setNoMoreData(isNoMoreData);
};

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export default function ScrollToTop(): any {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}
