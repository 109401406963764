import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownOmniIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40" width={40} height={40}>
    <rect width="40" height="40" rx="4" fill="#F5F5F5" />
    <g clipPath="url(#clip0_14334_3985)">
      <path d="M20.3333 10L17 15L13.6667 10H20.3333Z" fill="#FBB03B" />
      <path d="M10.3333 15L13.6667 20L17 15H10.3333Z" fill="#FBB03B" />
      <path d="M7 20L10.3333 15L13.6667 20H7Z" fill="#FBB03B" />
      <path d="M27 20L30.3333 15L33.6667 20H27Z" fill="#ED1C24" />
      <path d="M20.3333 10L23.6667 15L27 10H20.3333Z" fill="#ED1C24" />
      <path d="M17 15L20.3333 10L23.6667 15H17Z" fill="#F7931E" />
      <path d="M23.6667 15L27 20L30.3333 15H23.6667Z" fill="#ED1C24" />
      <path d="M17 15L13.6667 10L10.3333 15H17Z" fill="#FBB03B" />
      <path d="M23.6667 15L27 10L30.3333 15H23.6667Z" fill="#ED1C24" />
      <path d="M20.3333 30L17 25L13.6667 30H20.3333Z" fill="#F15A24" />
      <path d="M10.3333 25L13.6667 20L17 25H10.3333Z" fill="#ED1C24" />
      <path d="M7 20L10.3333 25L13.6667 20H7Z" fill="#FBB03B" />
      <path d="M27 20L30.3333 25L33.6667 20H27Z" fill="#ED1C24" />
      <path d="M20.3333 30L23.6667 25L27 30H20.3333Z" fill="#F15A24" />
      <path d="M17 25L20.3333 30L23.6667 25H17Z" fill="#F15A24" />
      <path d="M23.6667 25L27 20L30.3333 25H23.6667Z" fill="#C1272D" />
      <path d="M17 25L13.6667 30L10.3333 25H17Z" fill="#F15A24" />
      <path d="M23.6667 25L27 30L30.3333 25H23.6667Z" fill="#F15A24" />
    </g>
    <defs>
      <clipPath id="clip0_14334_3985">
        <rect
          width="26.6667"
          height="20"
          fill="white"
          transform="translate(7 10)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

DropdownOmniIcon.displayName = 'DropdownOmniIcon';
