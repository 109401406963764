import React, { FC, useState } from 'react';
import { openStatusNotification, ResetPasswordModal } from '@xq/ui-kit';
import { useTranslation } from 'react-i18next';
import { getStatusNotificationTranslations } from '@services';
import {
  UsersService,
  UsersServiceApi
} from '@pages/Users/Users/users-service';

interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  userFullName: string;
  userUuid: string;
}

export const ResetPassword: FC<ResetPasswordModalProps> = (props) => {
  const { isOpen, onClose, userFullName, userUuid } = props;
  const { t } = useTranslation();
  const service: UsersService = new UsersServiceApi();

  const [newPassword, setNewPassword] = useState<string>('');
  const [askUserNextTime, setAskUserNextTime] = useState<boolean>(false);

  async function resetPassword() {
    try {
      await service.resetPassword(userUuid, newPassword, askUserNextTime);

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            Password for <strong>{userFullName}</strong> has been set
          </span>
        )
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  return (
    <ResetPasswordModal
      t={t}
      isOpen={isOpen}
      onClose={onClose}
      userFullName={userFullName}
      resetPassword={resetPassword}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      askUserNextTime={askUserNextTime}
      setAskUserNextTime={setAskUserNextTime}
    />
  );
};

ResetPassword.displayName = 'ResetPasswordModal';
