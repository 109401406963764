import React, { FC } from 'react';
import classNames from 'classnames';
import { NavLinkProps } from 'react-router-dom';
import {
  activeStyle,
  ComponentProps,
  HeaderTranslations,
  Icon,
  NavItem,
  OrganizationItem,
  Typography
} from '@components';
import styles from './DesktopMenu.module.scss';
import { OrganizationDropdown } from '../OrganizationDropdown';
import { offsets } from '@componentsStyles';

export interface DesktopMenuProps extends ComponentProps {
  profileLinks: NavItem[];
  organizations: OrganizationItem[];
  currentOrganizationId: string;
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  translations?: HeaderTranslations;

  closeMenu?(): void;

  onOrganizationChange?(): void;

  onSignOut?(): void;
}

export const DesktopMenu: FC<DesktopMenuProps> = (props) => {
  const {
    id,
    style,
    className,
    profileLinks,
    organizations,
    currentOrganizationId,
    onOrganizationChange,
    closeMenu,
    onSignOut,
    translations
  } = props;

  const clickOnLink = (link: NavItem) => {
    if (link?.onClick) {
      link.onClick();
    }
    closeMenu();
  };

  return (
    <div
      className={classNames(className, styles.wrapper)}
      id={id}
      style={style}
    >
      <div>
        {organizations && organizations?.length > 0 && (
          <OrganizationDropdown
            organizations={organizations}
            currentOrganizationId={currentOrganizationId}
            onOrganizationChange={onOrganizationChange}
          />
        )}

        {profileLinks?.map((item, key) => {
          return (
            <a
              style={item?.isActive ? activeStyle : undefined}
              className={styles.link}
              key={key}
              onClick={() => clickOnLink(item)}
              href={item.path}
              target={item?.isOpenInNewTab ? '_blank' : '_self'}
              rel="noreferrer"
              data-testid="profile-link"
            >
              <Icon
                name={'user-settings'}
                size={'s'}
                className={offsets['mr-8']}
              />
              <Typography variant={'body-4'}>{item.name}</Typography>
            </a>
          );
        })}

        {onSignOut && (
          <div className={styles.link} onClick={onSignOut}>
            <Icon name={'sign-out'} size={'s'} className={offsets['mr-8']} />
            <Typography element={'div'} variant={'body-4'}>
              {translations?.signOut}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

DesktopMenu.displayName = 'DesktopMenu';
