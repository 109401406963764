import React, { FC } from 'react';
import {
  ComponentProps,
  HeaderApp,
  HeaderAppItem,
  HeaderAppNames,
  HeaderTranslations,
  Icon,
  Typography
} from '@components';
import styles from './AppSwitcher.module.scss';
import classNames from 'classnames';
import { useComponentVisible } from '@componentsUtils';
import { colors, offsets } from '@componentsStyles';

const indicator = require('@assets/images/indicator.svg');

export interface AppSwitcherProps extends ComponentProps {
  currentApp: HeaderApp;
  apps: HeaderAppItem[];
  translations?: HeaderTranslations;
}

export const AppSwitcher: FC<AppSwitcherProps> = (props) => {
  const { id, style, className, currentApp, apps, translations } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const dropdownItem = (appName: HeaderApp, apps: HeaderAppItem[]) => {
    if (!apps || apps.length === 0) {
      return;
    }
    const app = apps?.find((el) => el.name === appName);

    const getDescription = (appName: HeaderApp) => {
      switch (appName) {
        case HeaderAppNames.Home: {
          return translations.home;
        }
        case HeaderAppNames.Insights: {
          return translations.marketDataAnalysis;
        }
        case HeaderAppNames.Administration: {
          return translations.organizationAdministration;
        }
        case HeaderAppNames.Omni: {
          return translations.superAdministration;
        }
      }
    };

    return (
      app && (
        <a
          href={app?.url}
          className={styles['dropdown-item']}
          onClick={() => setIsComponentVisible(false)}
        >
          {app?.name === currentApp && (
            <img
              src={indicator}
              className={styles.indicator}
              alt={'current app indicator'}
            />
          )}
          <div className={styles['dropdown-data']}>
            <Icon name={`dropdown-${app.name}`} />
            <div>
              <Icon
                className={offsets['mb-6']}
                name={`dropdown-${app.name}-text`}
              />
              <Typography
                element={'div'}
                className={styles['dropdown-description']}
                variant={'label-2'}
                color={colors.gray60Color}
              >
                {getDescription(app.name)}
              </Typography>
            </div>
          </div>
        </a>
      )
    );
  };

  return (
    <div
      id={id}
      className={classNames(className, styles.wrapper)}
      style={style}
      ref={ref}
    >
      <div
        className={
          apps?.length > 1 ? styles.switcher : styles['switcher--disabled']
        }
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {apps?.length > 1 && (
          <Icon
            id="app-switcher"
            name={'switcher'}
            size={'m'}
            className={
              isComponentVisible ? styles['icon--active'] : styles.icon
            }
          />
        )}
        <Icon
          name={`logo-${currentApp}${
            isComponentVisible && apps?.length > 1 ? '-disabled' : ''
          }`}
        />
      </div>

      {isComponentVisible && apps?.length > 1 && (
        <div className={styles.dropdown}>
          {dropdownItem(HeaderAppNames.Home, apps)}
          {dropdownItem(HeaderAppNames.Insights, apps)}
          {dropdownItem(HeaderAppNames.Administration, apps)}
          {dropdownItem(HeaderAppNames.Omni, apps)}
        </div>
      )}
    </div>
  );
};

AppSwitcher.displayName = 'AppSwitcher';
