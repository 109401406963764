import React, { FC } from 'react';
import classNames from 'classnames';
import { NavLinkProps } from 'react-router-dom';
import {
  activeStyle,
  Avatar,
  ComponentProps,
  HeaderTranslations,
  Icon,
  NavItem,
  OrganizationItem,
  Typography
} from '@components';
import styles from './TabletMenu.module.scss';
import { OrganizationDropdown } from '../OrganizationDropdown';
import { HeaderNavLink } from '@components/Header/components/HeaderNavLink';
import { offsets } from '@componentsStyles';

export interface TabletMenuProps extends ComponentProps {
  navItems?: NavItem[];
  profileLinks: NavItem[];
  organizations: OrganizationItem[];
  currentOrganizationId: string;
  name: string;
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  avatar?: string;
  translations?: HeaderTranslations;

  closeMenu?(): void;

  onOrganizationChange?(): void;

  onSignOut?(): void;
}

export const TabletMenu: FC<TabletMenuProps> = (props) => {
  const {
    id,
    className,
    style,
    avatar,
    name,
    navItems,
    profileLinks,
    organizations,
    currentOrganizationId,
    onOrganizationChange,
    closeMenu,
    onSignOut,
    NavLink,
    translations
  } = props;

  const onLinkClick = (link: NavItem) => {
    if (link?.onClick) {
      link.onClick();
    }
    closeMenu();
  };

  return (
    <div
      className={classNames(className, styles.wrapper)}
      id={id}
      style={style}
    >
      <nav className={styles.navigation}>
        {navItems?.length > 0 &&
          navItems?.map((item, key) => {
            return (
              <HeaderNavLink
                key={key}
                className={styles['nav-item']}
                NavLink={NavLink}
                navItem={item}
                onNavItemClick={() => onLinkClick(item)}
              />
            );
          })}
      </nav>
      <div className={styles.profile}>
        <div className={styles.flex}>
          <Avatar image={avatar} name={name} size={'small'} disabled />
          <Typography className={styles.name} variant={'body-5'}>
            {name}
          </Typography>
        </div>

        <OrganizationDropdown
          organizations={organizations}
          currentOrganizationId={currentOrganizationId}
          onOrganizationChange={onOrganizationChange}
        />
        {profileLinks?.map((item, key) => {
          return (
            <a
              style={item?.isActive ? activeStyle : undefined}
              className={styles.link}
              key={key}
              onClick={() => onLinkClick(item)}
              href={item.path}
              target={item?.isOpenInNewTab ? '_blank' : '_self'}
              rel="noreferrer"
              data-testid="profile-link"
            >
              <Icon
                name={'user-settings'}
                size={'s'}
                className={offsets['mr-8']}
              />
              <Typography variant={'body-4'}>{item.name}</Typography>
            </a>
          );
        })}

        {onSignOut && (
          <div className={styles.link} onClick={onSignOut}>
            <Icon name={'sign-out'} size={'s'} className={offsets['mr-8']} />
            <Typography element={'div'} variant={'body-4'}>
              {translations?.signOut}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

TabletMenu.displayName = 'TabletMenu';
