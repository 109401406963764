import React, { FC, useEffect, useRef, useState } from 'react';
import {
  MenuItem,
  openStatusNotification,
  Sidemenu,
  useCurrentWidth
} from '@xq/ui-kit';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import styles from './SidemenuLayout.module.scss';
import { SidemenuContext } from '@context';
import {
  SidemenuLayoutLicense,
  SidemenuLayoutService,
  SidemenuLayoutServiceApi
} from './sidemenu-layout-service';
import {
  generateLicensesSidemenu,
  generateOrganizationSidemenu,
  getStatusNotificationTranslations
} from '@services';
import { useTranslation } from 'react-i18next';
import { OrganizationName } from 'interfaces';
import { SECTION_URL } from '@router';

interface SidemenuLayoutProps {}

export const SidemenuLayout: FC<SidemenuLayoutProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const service: SidemenuLayoutService = new SidemenuLayoutServiceApi();

  const [activeMenu, setActiveMenu] = useState<string>('');
  const [sideMenuWidth, setSideMenuWidth] = useState<number>(null);
  const [licenses, setLicenses] = useState<SidemenuLayoutLicense[]>([]);
  const [isSidemenuLoaded, setIsSidemenuLoaded] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<OrganizationName[]>([]);
  const [currentOrganization, setCurrentOrganization] =
    useState<OrganizationName>();
  const [activeSection, setActiveSection] = useState<string>('');

  const [sideMenuContentClassName, setSideMenuContentClassName] =
    useState<string>('');

  const { width } = useCurrentWidth();

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setSideMenuWidth(ref.current.clientWidth);
  }, [width]);

  const [sidemenuItems, setSidemenuItems] = useState<MenuItem[]>([]);
  const sidemenuContextData = {
    activeMenu,
    setActiveMenu,
    sidemenuItems,
    setSidemenuItems,
    licenses,
    setLicenses,
    currentOrganization,
    setCurrentOrganization,
    organizations,
    setOrganizations,
    isSidemenuLoaded,
    setIsSidemenuLoaded
  };

  async function fetchData() {
    setIsSidemenuLoaded(false);
    try {
      const response = await service.fetchData();
      setLicenses(response?.licenses);
      setCurrentOrganization(response?.currentOrganization);
      setOrganizations(response?.organizations);
      setIsSidemenuLoaded(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  //todo: watch on changing licenses and current org in other pages
  useEffect(() => {
    let sidemenu;
    switch (activeSection) {
      case SECTION_URL.ORGANIZATION: {
        sidemenu = generateOrganizationSidemenu(t, currentOrganization);
        break;
      }

      case SECTION_URL.LICENSES: {
        sidemenu = generateLicensesSidemenu(t, licenses);
        break;
      }
    }

    setSidemenuItems(sidemenu);
  }, [licenses, currentOrganization, activeSection]);

  useEffect(() => {
    if (!location?.pathname) {
      setActiveSection(null);
      return;
    }

    //todo then replace it with some meta in router
    let mainLocation = location.pathname?.split('/')[1];
    if (!mainLocation || mainLocation === '') {
      mainLocation = SECTION_URL.ORGANIZATION;
    }

    setActiveSection(mainLocation);
  }, [location?.pathname]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    function updateSideMenuStyleVisualTour(param: { key: string; value: any }) {
      if (param) {
        if (param.key === 'stepIndex' && param.value > 3) {
          setSideMenuContentClassName(styles['visual-tour-position']);
        } else if (param.key === 'stepIndex' && param.value <= 3) {
          setSideMenuContentClassName('');
        }
        if (param.key === 'enableTour' && param.value === 'false') {
          setSideMenuContentClassName('');
        }
      }
    }

    window.addEventListener('storage', (e: CustomEvent) => {
      updateSideMenuStyleVisualTour(e.detail);
    });
    return () => {
      window.removeEventListener('storage', (e) => (e: CustomEvent) => {
        updateSideMenuStyleVisualTour(e.detail);
      });
    };
  }, []);

  return (
    <div className={styles.layout}>
      <SidemenuContext.Provider value={sidemenuContextData}>
        <div ref={ref} id="layout-sidemenu-wrapper" style={{ height: '100%' }}>
          {activeSection && (
            <Sidemenu
              id="layout-sidemenu"
              items={sidemenuItems}
              activeMenuKey={activeMenu}
              setActiveMenuKey={setActiveMenu}
              className={styles.sidemenu}
              contentClassName={sideMenuContentClassName}
              NavLink={NavLink}
            />
          )}
        </div>

        <div
          style={{ width: `calc(100% - ${sideMenuWidth}px)` }}
          className={styles.page}
        >
          <Outlet />
        </div>
      </SidemenuContext.Provider>
    </div>
  );
};

SidemenuLayout.displayName = 'SidemenuLayout';
