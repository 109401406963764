import React, { FC } from 'react';
import styles from '@components/Uploader/components/File.module.scss';
import { Icon, Tooltip, Typography } from '@components';
import { colors, offsets } from '@componentsStyles';
import { displayFileName } from '@components/Uploader/components/utils';

interface FileNameProps {
  name: string;
  byteSize: number;
  textColor: string;
  isError?: boolean;
  loadingFailedText?: string;
}

export const FileName: FC<FileNameProps> = (props) => {
  const { name, byteSize, isError, textColor, loadingFailedText } = props;

  return (
    <Tooltip
      type={'topLeft'}
      content={name}
      className={styles['file-block-tooltip']}
    >
      <div className={styles['file-wrap']}>
        <div
          className={
            isError ? styles['file-block-error'] : styles['file-block']
          }
        >
          <Icon
            style={{ fill: textColor }}
            size="s"
            className={offsets['mr-8']}
            name="paperclip"
          />

          <Typography
            color={textColor}
            className={styles['file-name']}
            variant="label-1"
          >
            {displayFileName(name, byteSize).nameStart}
          </Typography>
          <Typography
            color={textColor}
            variant="label-1"
            className={styles['file-name-end']}
          >
            {displayFileName(name, byteSize).nameEnd}
          </Typography>
          <Typography
            color={textColor}
            variant="label-1"
            className={offsets['ml-8']}
          >
            {displayFileName(name, byteSize).size}
          </Typography>
        </div>

        {isError && (
          <Typography element="div" color={colors.red70Color} variant="label-1">
            {loadingFailedText}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
};
