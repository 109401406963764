import React, { FC, useEffect, useState } from 'react';
import { colors } from '@componentsStyles';
import { Icon, IconNames } from '@components';
import { FileInfo } from '../../dataTypes';
import styles from './../File.module.scss';
import { FileName } from '@components/Uploader/components/FileName';

interface UploadedFileProps {
  fileInfo: FileInfo;
  deleteFile: (file: FileInfo, event: any) => void;
  disabled?: boolean;
  loadingFailedText?: string;
  onClick?: (file: FileInfo) => void;
}

export const UploadedFile: FC<UploadedFileProps> = (props) => {
  const { disabled, fileInfo, deleteFile, loadingFailedText, onClick } = props;

  let timerId: NodeJS.Timeout = null;
  const now = new Date();

  const defaultIcon = (): IconNames => {
    if (fileInfo.status === 'error' || !fileInfo.uploadingTime) {
      return 'trash';
    }

    const timeDiff = now.getSeconds() - fileInfo.uploadingTime.getSeconds();
    const delay = 1000;

    if (timeDiff <= delay) {
      if (!timerId) {
        timerId = setTimeout(() => {
          setIcon('trash');
          fileInfo.uploadingTime = null;
        }, delay - timeDiff);
      }
      return 'success';
    }
  };

  useEffect(() => {
    setIcon(defaultIcon());
    return () => clearTimeout(timerId);
  }, [fileInfo]);

  const fillColor = (): string => {
    if (disabled) {
      return colors.gray20Color;
    }
    if (icon === 'success') {
      return colors.green70Color;
    }
    if (fileInfo.status === 'error') {
      return colors.red70Color;
    }

    return colors.gray60Color;
  };

  const isError = fileInfo.status === 'error';
  const textColor = isError ? colors.gray40Color : colors.gray100Color;

  const [icon, setIcon] = useState<IconNames>(null);

  return (
    <div className={styles.file} onClick={() => onClick(fileInfo)}>
      <FileName
        name={fileInfo.file.name}
        byteSize={fileInfo.file.size}
        textColor={textColor}
        isError={isError}
        loadingFailedText={loadingFailedText}
      />

      <div
        onClick={(event: any) =>
          disabled || icon === 'success' ? null : deleteFile(fileInfo, event)
        }
      >
        <Icon
          className={
            disabled ? styles['file-icon--disabled'] : styles['file-icon']
          }
          size="m"
          data-testid="file-icon"
          name={icon}
          style={{
            fill: fillColor()
          }}
        />
      </div>
    </div>
  );
};
