import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoModelTreeSmallIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 33 26" width={33} height={26}>
    <path d="M17.1052 0L0 16.25V26L22.2368 4.875L17.1052 0Z" fill="#BE1E2D" />
    <path
      d="M22.2378 4.875L10.2642 16.25V26L27.3694 9.75L22.2378 4.875Z"
      fill="#F15A29"
    />
    <path
      d="M20.5283 16.25L27.3704 9.75L32.502 14.625L20.5283 26V16.25Z"
      fill="#FBB040"
    />
  </SvgIcon>
);

LogoModelTreeSmallIcon.displayName = 'LogoModelTreeSmallIcon';
