import { VisualTourStepItemProps } from '@xq/ui-kit';
import React from 'react';
import { getRouteUrl, ROUTES } from '@router';
import { TFunction } from 'i18next';

const spotlightPadding = 10;

export const visualTourTimeout = 1000;

export const getStepsSource = (t: TFunction): VisualTourStepItemProps[] => {
  return [
    //Organization
    {
      target: '#app-switcher',
      title: t('visualTour.organization.appSwitcher.title'),
      content: t('visualTour.organization.appSwitcher.content'),
      placement: 'left-start',
      spotlightPadding
    },
    {
      target: '#nav-visual-tour',
      content: t('visualTour.organization.navigation.content'),
      placement: 'bottom',
      spotlightPadding
    },
    {
      target: '#drop-down-menu',
      content: t('visualTour.organization.dropDownMenu.content'),
      placement: 'bottom'
    },
    {
      target: '#sidebar',
      content: t('visualTour.organization.layoutSideMenu.content'),
      placement: 'right-start'
    },
    {
      target: '#licenses',
      content: t('visualTour.organization.licenses.content'),
      placement: 'bottom-left'
    },
    {
      target: '#organization-info',
      content: t('visualTour.organization.organizationInfo.content'),
      placement: 'right'
    },
    {
      id: 'MAIN_PAGE_USERS_STEP',
      target: '#organization-users',
      content: t('visualTour.organization.organizationUsers.content'),
      placement: 'right'
    },
    {
      id: 'MAIN_PAGE_CONTACT_PERSON_STEP',
      target: '#organization-person-visual-tour',
      content: t(
        'visualTour.organization.organizationPersonVisualTour.content'
      ),
      data: {
        next: getRouteUrl(ROUTES.ORGANIZATION.CONTACT_PERSONS)
      },
      placement: 'right',
      spotlightPadding
    },
    //Contact persons
    {
      target: '#contact-person-visual-tour',
      content: t('visualTour.contactPersons.contactPersonVisualTour.content'),
      data: {
        previous: getRouteUrl(ROUTES.ORGANIZATION.ORGANIZATION),
        next: getRouteUrl(ROUTES.ORGANIZATION.SECURITY_SETTINGS)
      },
      placement: 'bottom',
      spotlightPadding
    },
    //Security Settings
    {
      target: '#security-settings-email-visual-tour',
      content: t(
        'visualTour.securitySettings.securitySettingsEmailVisualTour.content'
      ),
      data: {
        previous: getRouteUrl(ROUTES.ORGANIZATION.CONTACT_PERSONS),
        next: getRouteUrl(ROUTES.USERS.USERS)
      },
      placement: 'bottom',
      spotlightPadding
    },
    //Users
    {
      target: '#users-info-data-visual-tour',
      content: t('visualTour.users.usersInfoDataVisualTour.content'),
      data: {
        previous: getRouteUrl(ROUTES.ORGANIZATION.SECURITY_SETTINGS)
      },
      placement: 'top-right'
    },
    {
      target: '#first-table-row-menu-icon',
      content: t('visualTour.users.usersListActionsVisualTour.content'),
      placement: 'top-right',
      spotlightPadding
    },
    {
      target: '#users-create-user-visual-tour',
      content: t('visualTour.users.usersCreateUserVisualTour.content'),
      data: {
        next: getRouteUrl(ROUTES.USERS.CREATE_USER)
      }
    },
    //Create User
    {
      id: 'CREATE_USER_PAGE_FORM_STEP',
      target: '#create_user-input-visual-tour',
      content: t('visualTour.users.usersCreateUserInputFormVisualTour.content'),
      data: {
        previous: getRouteUrl(ROUTES.USERS.USERS),
        next: null
      },
      placement: 'right-start',
      spotlightPadding
    },
    {
      id: 'CREATE_USER_PAGE_LICENSES_STEP',
      target: '#create_user-licenses-visual-tour',
      content: t('visualTour.users.usersCreateUserLicencesVisualTour.content'),
      data: {
        next: getRouteUrl(ROUTES.LICENSES.LICENSES)
      },
      placement: 'left-start',
      spotlightPadding
    },
    //Licences
    {
      target: '#sidebar',
      content: t('visualTour.licenses.licencesSidemenuVisualTour.content'),
      data: {
        previous: getRouteUrl(ROUTES.USERS.CREATE_USER)
      },
      placement: 'right-start'
    },
    {
      target: '#licences-review-visual-tour',
      content: t('visualTour.licenses.licencesGeneralInfoVisualTour.content'),
      placement: 'bottom',
      spotlightPadding
    },
    {
      id: 'LICENSE_PAGE_ACTIVE_USERS_STEP',
      target: '#licences-active-users-visual-tour',
      content: t('visualTour.licenses.licencesActiveUsersVisualTour.content'),
      placement: 'top',
      spotlightPadding
    },
    {
      id: 'LICENSE_PAGE_INACTIVE_USERS_STEP',
      target: '#inactive-users',
      content: t('visualTour.licenses.licencesDataAttachedVisualTour.content'),
      placement: 'top',
      spotlightPadding
    }
  ]?.map((step) => {
    if (step.content.includes('\n')) {
      const parts: string[] = step.content.split('\n');
      if (parts.length < 2) {
        return step;
      }
      // @ts-ignore
      step.content = (
        <span>
          {parts?.map((el, index) => {
            return (
              <>
                {el} {index < parts.length - 1 && <br />}
              </>
            );
          })}
        </span>
      );
    }
    return step;
  });
};

export const getMergedURLSearchParams = (
  currentParams: URLSearchParams,
  targetParams?: URLSearchParams
): string => {
  if (!targetParams) {
    return '';
  }
  const currentObj = Object.fromEntries(currentParams.entries());
  const targetObj = Object.fromEntries(targetParams.entries());

  // Merge the objects, giving priority to targetObj
  const mergedObj = { ...currentObj, ...targetObj };

  for (const key in targetObj) {
    if (targetObj[key] === 'null') {
      delete mergedObj[key];
    }
  }

  // Convert the merged object back into a URLSearchParams object
  const mergedParams = new URLSearchParams(mergedObj);
  const mergedParamsString = mergedParams.toString();

  return mergedParamsString ? `?${mergedParamsString}` : '';
};
