import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const SaveIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 10 12" {...props}>
    <path
      d="M9.66732 12L5.00065 8.66667L0.333984 12V1.33333C0.333984 0.979711 0.47446 0.640573 0.724509 0.390524C0.974557 0.140476 1.3137 0 1.66732 0H8.33398C8.68761 0 9.02674 0.140476 9.27679 0.390524C9.52684 0.640573 9.66732 0.979711 9.66732 1.33333V12Z"
      fill={props.color}
    />
  </SvgIcon>
);

SaveIcon.displayName = 'SaveIcon';
