import React, { FC } from 'react';
import { colors } from '@componentsStyles';
import { ComponentProps, Icon, SavedFileName } from '@components';
import styles from './../File.module.scss';
import cn from 'classnames';
import { logger } from '@componentsUtils';
import { FileName } from '@components/Uploader/components/FileName';

export interface SavedFileProps extends ComponentProps {
  fileName: SavedFileName;
  deleteFile: (backendFile: SavedFileName, event: any) => void;
  disabled?: boolean;
  onSaveBackendFile?: (file: SavedFileName) => Promise<void>;
}

export const SavedFile: FC<SavedFileProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    disabled,
    fileName,
    deleteFile,
    onSaveBackendFile
  } = props;

  const fillColor = (): string => {
    if (disabled) {
      return colors.gray20Color;
    }
    return colors.gray60Color;
  };

  async function saveBackendFile(fileName: SavedFileName) {
    try {
      await onSaveBackendFile(fileName);
    } catch (error) {
      logger.error(`Error in uploader saveBackendFile: `, error?.message);
    }
  }

  return (
    <div
      id={id}
      style={style}
      className={cn(styles.file, className)}
      data-testid={dataTestId}
      onClick={() => saveBackendFile(fileName)}
    >
      <FileName
        name={fileName.name}
        byteSize={fileName.byteSize}
        textColor={colors.gray100Color}
      />

      <div onClick={(event) => (disabled ? null : deleteFile(fileName, event))}>
        <Icon
          className={
            disabled ? styles['file-icon--disabled'] : styles['file-icon']
          }
          size="m"
          data-testid="file-icon"
          name={'trash'}
          style={{
            fill: fillColor()
          }}
        />
      </div>
    </div>
  );
};
