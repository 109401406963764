import {
  HeaderAppItem,
  HeaderAppNames,
  LicenseName,
  LicenseNames
} from '@components/Header/dataTypes';

export const MODEL_TREE_DESKTOP_UUID = 'afd851cb-bb8e-4b16-9f4f-597445b0177f';
export const INSIGHTS_UUID = 'b4749d52-34ec-4905-8c0b-ca56fc9c0dca';

export interface FrontendAppUrls {
  modelTreeFrontendUrl: string;
  insightsFrontendUrl: string;
}

export interface HeaderAppSwitcherUrls {
  homeFrontendUrl: string;
  insightsFrontendUrl: string;
  administrationFrontendUrl: string;
  omniFrontendUrl: string;
}

export const getLicenseNameByUUID = (uuid: string): LicenseName => {
  switch (uuid) {
    case MODEL_TREE_DESKTOP_UUID: {
      return LicenseNames.ModelTree;
    }
    case INSIGHTS_UUID: {
      return LicenseNames.Insights;
    }
    default: {
      return;
    }
  }
};

export const generateHeaderAppSwitcherItems = (
  urls: HeaderAppSwitcherUrls,
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const homeApp: HeaderAppItem = {
    name: HeaderAppNames.Home,
    url: urls.homeFrontendUrl
  };

  const exquanceInsightsApp: HeaderAppItem = {
    name: HeaderAppNames.Insights,
    url: urls.insightsFrontendUrl
  };

  const adminApp: HeaderAppItem = {
    name: HeaderAppNames.Administration,
    url: urls.administrationFrontendUrl
  };

  const omniApp: HeaderAppItem = {
    name: HeaderAppNames.Omni,
    url: urls.omniFrontendUrl
  };

  const apps: HeaderAppItem[] = [homeApp, exquanceInsightsApp];

  if (isOrganizationAdmin) {
    apps.push(adminApp);
  }

  if (isXQAdmin) {
    apps.push(omniApp);
  }

  return apps;
};
