import React, { FC } from 'react';
import { ComponentProps, Typography } from '@components';
import styles from './Chevron.module.scss';
import { colors } from '@componentsStyles';

export interface ChevronProps extends ComponentProps {
  disabled?: boolean;
  backgroundColor?: string;
}

export const Chevron: FC<ChevronProps> = (props) => {
  const {
    className,
    children,
    id,
    style,
    disabled = false,
    dataTestId,
    backgroundColor = colors.gold
  } = props;

  const background = disabled ? colors.gray40Color : backgroundColor;

  return (
    <div id={id} style={style} className={className} data-testid={dataTestId}>
      <div className={styles.chevron} style={{ backgroundColor: background }}>
        <Typography variant="chevron" className={styles.title}>
          {children}
        </Typography>
      </div>
    </div>
  );
};

Chevron.displayName = 'Chevron';
