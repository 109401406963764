import { logger } from '@xq/ui-kit';

export enum LocalStorageKey {
  lang = 'lang',
  /* Is quick start guide was started someday */
  wasQuickStartGuideShown = 'wasQuickStartGuideShown'
}

export enum wasQuickStartGuideShownValues {
  yes = 'yes',
  no = 'no'
}

export const getLocalStorageItem = (key: LocalStorageKey) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    logger.error(`Local storage error: key ${key} is not exist`, error);
  }
};

export const setLocalStorageItem = (key: LocalStorageKey, value: string) => {
  try {
    localStorage.setItem(key, value);

    const event = new CustomEvent('storage', { detail: { key, value } });
    window.dispatchEvent(event);
  } catch (error) {
    logger.error(`Local storage error: key ${key}, value: ${value}`, error);
  }
};

export const removeLocaleStorageItem = (key: LocalStorageKey) => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    logger.error(`Local storage error: key ${key} is not exist`, error);
  }
};
