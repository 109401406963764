import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const MessageReportIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00016 3.33333C3.64654 3.33333 3.3074 3.47381 3.05735 3.72386C2.80731 3.97391 2.66683 4.31304 2.66683 4.66667V10C2.66683 10.3536 2.80731 10.6928 3.05735 10.9428C3.3074 11.1929 3.64654 11.3333 4.00016 11.3333H5.3335C5.70169 11.3333 6.00016 11.6318 6.00016 12V12.8225L8.32383 11.4283C8.42744 11.3662 8.546 11.3333 8.66683 11.3333H12.0002C12.3538 11.3333 12.6929 11.1929 12.943 10.9428C13.193 10.6928 13.3335 10.3536 13.3335 10V4.66667C13.3335 4.31304 13.193 3.97391 12.943 3.72386C12.6929 3.47381 12.3538 3.33333 12.0002 3.33333H4.00016ZM2.11454 2.78105C2.61464 2.28095 3.29292 2 4.00016 2H12.0002C12.7074 2 13.3857 2.28095 13.8858 2.78105C14.3859 3.28115 14.6668 3.95942 14.6668 4.66667V10C14.6668 10.7072 14.3859 11.3855 13.8858 11.8856C13.3857 12.3857 12.7074 12.6667 12.0002 12.6667H8.85149L5.67649 14.5717C5.47054 14.6952 5.21404 14.6985 5.00503 14.5801C4.79602 14.4618 4.66683 14.2402 4.66683 14V12.6667H4.00016C3.29292 12.6667 2.61464 12.3857 2.11454 11.8856C1.61445 11.3855 1.3335 10.7072 1.3335 10V4.66667C1.3335 3.95942 1.61445 3.28115 2.11454 2.78105ZM8.00016 4.66667C8.36835 4.66667 8.66683 4.96514 8.66683 5.33333V7.33333C8.66683 7.70152 8.36835 8 8.00016 8C7.63197 8 7.3335 7.70152 7.3335 7.33333V5.33333C7.3335 4.96514 7.63197 4.66667 8.00016 4.66667ZM8.00016 8.66667C8.36835 8.66667 8.66683 8.96514 8.66683 9.33333V9.34C8.66683 9.70819 8.36835 10.0067 8.00016 10.0067C7.63197 10.0067 7.3335 9.70819 7.3335 9.34V9.33333C7.3335 8.96514 7.63197 8.66667 8.00016 8.66667Z"
      fill={props.color}
    />
  </SvgIcon>
);

MessageReportIcon.displayName = 'MessageReportIcon';
