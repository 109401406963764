import {
  colors,
  formatDate,
  Icon,
  offsets,
  TableColumn,
  TableRow,
  Tooltip,
  Typography,
  UserTableBlock
} from '@xq/ui-kit';
import styles from '@pages/Users/Users/Users.module.scss';
import React, { Fragment } from 'react';
import { User } from '@pages';
import { TFunction } from 'i18next';
import { userTableBlockTranslations } from '@services';

export function createUserRows(t: TFunction, users: User[]): TableRow[] {
  return users?.map((user) => ({
    id: user?.uuid,
    data: {
      user: (
        <UserTableBlock
          user={{
            fullname: user?.fullname,
            email: user?.email,
            avatar: user?.avatar,
            isExternal: user?.isExternal,
            isOrganizationAdmin: user?.isOrganizationAdmin,
            fromAzure: user?.fromAzure
          }}
          showAvatar
          isActive
          translations={userTableBlockTranslations(t)}
        />
      ),
      license: (
        <Fragment>
          {user?.licenses?.map((license, index) => (
            <Typography
              element="div"
              className={index !== 0 && offsets['mt-8']}
              key={index}
              variant="body-4"
            >
              {license}
            </Typography>
          ))}
        </Fragment>
      ),
      status: (
        <Typography
          variant="body-4"
          className={styles['table-status']}
          color={user?.isBlocked ? colors.palettesRed80 : colors.green70Color}
        >
          <Icon
            color={user?.isBlocked ? 'error' : 'success'}
            name="status"
            size="s"
          />
          {user?.isBlocked ? t('common.blocked') : t('common.active')}
          {user?.blockReason && (
            <Tooltip className={offsets['ml-8']} content={user?.blockReason}>
              <Icon name={'info'} size={'s'} color={'error'} />
            </Tooltip>
          )}
        </Typography>
      ),
      createdAt: (
        <Typography element="div" variant="body-4">
          {formatDate(user?.createdAt)}
        </Typography>
      )
    }
  }));
}

export function createUserColumns(t: TFunction): TableColumn[] {
  return [
    {
      title: t('common.user'),
      key: 'user',
      width: 310,
      alignment: 'left',
      paddingLeft: 20,
      paddingRight: 20,
      sortable: true
    },
    {
      title: t('common.status'),
      key: 'status',
      width: 110,
      alignment: 'left',
      paddingRight: 20,
      sortable: true
    },
    {
      title: t('common.license'),
      key: 'license',
      width: 176,
      alignment: 'left',
      paddingLeft: 20,
      sortable: false
    },
    {
      title: t('common.createOn'),
      key: 'createdAt',
      width: 110,
      alignment: 'left',
      paddingLeft: 20,
      sortable: true
    }
  ];
}

export const mapColumnKey = (key: string): string => {
  switch (key) {
    case 'user': {
      return 'fullname';
    }
    case 'status': {
      return 'isBlocked';
    }
    default: {
      return key;
    }
  }
};
