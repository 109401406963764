import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownHomeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40" width={40} height={40}>
    <rect width="40" height="40" rx="4" fill="#F5F5F5" />
    <path d="M6 10L9.33333 15L12.6667 10H6Z" fill="#FBB040" />
    <path d="M24.3325 25L27.6659 20L30.9992 25H24.3325Z" fill="#F26522" />
    <path d="M21 10L24.3333 15L27.6667 10H21Z" fill="#EE2A7B" />
    <path d="M17.6675 15L21.0008 10L24.3341 15H17.6675Z" fill="#DA1C5C" />
    <path d="M17.6675 15L21.0008 20L24.3341 15H17.6675Z" fill="#BE1E2D" />
    <path d="M12.6675 20L16.0008 15L19.3341 20H12.6675Z" fill="#F15A29" />
    <path d="M12.6675 20L16.0008 25L19.3341 20H12.6675Z" fill="#EF4136" />
    <path d="M9.33252 25L12.6659 20L15.9992 25H9.33252Z" fill="#BE1E2D" />
    <path d="M24.3325 25L27.6659 30L30.9992 25H24.3325Z" fill="#F7941D" />
    <path d="M27.6675 30L31.0008 25L34.3341 30H27.6675Z" fill="#FBB040" />
    <path d="M9.33252 15L12.6659 20L15.9992 15H9.33252Z" fill="#F26522" />
    <path d="M9.33252 15L12.6659 10L15.9992 15H9.33252Z" fill="#F7941D" />
    <path d="M21 20L24.3333 25L27.6667 20H21Z" fill="#F15A29" />
    <path d="M21 20L24.3333 15L27.6667 20H21Z" fill="#EF4136" />
  </SvgIcon>
);

DropdownHomeIcon.displayName = 'DropdownHomeIcon';
