import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownOmniText: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 31 12" width={31} height={12}>
    <path
      d="M3.632 11.12C2.85333 11.12 2.192 10.9227 1.648 10.528C1.11467 10.1333 0.704 9.552 0.416 8.78401C0.138667 8.01601 0 7.088 0 6C0 4.93334 0.138667 4.02134 0.416 3.264C0.704 2.496 1.11467 1.90934 1.648 1.504C2.192 1.088 2.85333 0.880005 3.632 0.880005C4.41067 0.880005 5.06667 1.088 5.6 1.504C6.13333 1.90934 6.53867 2.496 6.816 3.264C7.104 4.02134 7.248 4.93334 7.248 6C7.248 7.088 7.104 8.01601 6.816 8.78401C6.53867 9.552 6.13333 10.1333 5.6 10.528C5.06667 10.9227 4.41067 11.12 3.632 11.12ZM3.632 9.984C4.10133 9.984 4.50133 9.83467 4.832 9.53601C5.17333 9.22667 5.42933 8.77867 5.6 8.19201C5.78133 7.59467 5.872 6.864 5.872 6C5.872 5.17867 5.78133 4.47467 5.6 3.888C5.42933 3.29067 5.17333 2.832 4.832 2.512C4.50133 2.192 4.10133 2.032 3.632 2.032C3.16267 2.032 2.75733 2.192 2.416 2.512C2.07467 2.832 1.81333 3.29067 1.632 3.888C1.46133 4.47467 1.376 5.17867 1.376 6C1.376 6.864 1.46133 7.59467 1.632 8.19201C1.81333 8.77867 2.07467 9.22667 2.416 9.53601C2.75733 9.83467 3.16267 9.984 3.632 9.984Z"
      fill="#121212"
    />
    <path
      d="M8.224 10.944V0.976005H9.344L11.632 5.76L13.952 0.960005H15.04V10.944H13.808V3.616L11.856 7.504H11.264L9.44 3.648V10.944H8.224Z"
      fill="#121212"
    />
    <path
      d="M16.352 0.976005H17.696L21.616 8.09601L21.584 0.976005H22.832H22.992V1.104C22.928 1.168 22.8853 1.23734 22.864 1.312C22.8533 1.38667 22.848 1.51467 22.848 1.696V10.944H21.696L17.6 3.392V10.944H16.352V0.976005Z"
      fill="#121212"
    />
    <path
      d="M24.848 10.944V9.82401H26.816V2.096H24.96V0.976005H30.096V2.096H28.128V9.84H30.192V10.944H24.848Z"
      fill="#121212"
    />
  </SvgIcon>
);

DropdownOmniText.displayName = 'DropdownOmniText';
