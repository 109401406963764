import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownInsightsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40" width={40} height={40}>
    <rect width="40" height="40" rx="4" fill="#F5F5F5" />
    <path
      d="M15.4117 8L26.7058 13.647V19.2941L15.4117 13.647V8Z"
      fill="#BE1622"
    />
    <path
      d="M26.7058 13.647L14 19.9999V25.6469L26.7058 19.294V13.647Z"
      fill="#E94E1B"
    />
    <path
      d="M26.7058 19.2939L14 25.6468L19.647 28.4703L26.7058 24.9409V19.2939Z"
      fill="#F39200"
    />
    <path
      d="M26.706 24.9412L19.6472 28.4706L26.706 32V28.4706V24.9412Z"
      fill="#F9B233"
    />
  </SvgIcon>
);

DropdownInsightsIcon.displayName = 'DropdownInsightsIcon';
