import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const FilterIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 16" {...props}>
    <path
      d="M11.1999 12.7988C11.8626 12.7988 12.3999 13.3361 12.3999 13.9988C12.3999 14.6616 11.8626 15.1988 11.1999 15.1988H8.7999C8.13716 15.1988 7.5999 14.6616 7.5999 13.9988C7.5999 13.3361 8.13716 12.7988 8.7999 12.7988H11.1999ZM14.7999 6.79883C15.4626 6.79883 15.9999 7.33609 15.9999 7.99883C15.9999 8.66157 15.4626 9.19883 14.7999 9.19883H5.1999C4.53716 9.19883 3.9999 8.66157 3.9999 7.99883C3.9999 7.33609 4.53716 6.79883 5.1999 6.79883H14.7999ZM18.3999 0.798828C19.0626 0.798828 19.5999 1.33609 19.5999 1.99883C19.5999 2.66157 19.0626 3.19883 18.3999 3.19883H1.5999C0.93716 3.19883 0.399902 2.66157 0.399902 1.99883C0.399902 1.33609 0.93716 0.798828 1.5999 0.798828H18.3999Z"
      fill={props.color}
    />
  </SvgIcon>
);

FilterIcon.displayName = 'FilterIcon';
