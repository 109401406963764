import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Header,
  HeaderApp,
  HeaderAppNames,
  NavItem,
  NotificationContainer,
  openStatusNotification,
  ScrollTopButton,
  VisualTour
} from '@xq/ui-kit';
import styles from './Layout.module.scss';
import {
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutService, LayoutServiceApi } from './layout-service';
import { config } from '@config';
import { getRouteUrl, ROUTES } from '@router';
import { UserContext, UserContextData, useVisualTourContext } from '@context';
import {
  getLocalStorageItem,
  getStatusNotificationTranslations,
  logger,
  setLocalStorageItem
} from '@services';
import { LayoutData } from './dataTypes';
import ScrollToTop from '@services/utils';
import { redirectToSSOLoginPage } from './utils';
import {
  SidemenuLayoutLicense,
  SidemenuLayoutService,
  SidemenuLayoutServiceApi
} from '@layouts/SidemenuLayout/sidemenu-layout-service';
import { CallBackProps } from 'react-joyride';
import { getMergedURLSearchParams, getStepsSource } from '@services/visualTour';
import {
  LocalStorageKey,
  wasQuickStartGuideShownValues
} from '@services/localStorage';

const currentApp: HeaderApp = HeaderAppNames.Administration;

export const Layout: FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const service: LayoutService = new LayoutServiceApi();
  const sidemenuService: SidemenuLayoutService = new SidemenuLayoutServiceApi();

  const [userData, setUserData] = useState<LayoutData>(null);
  const [licenses, setLicenses] = useState<SidemenuLayoutLicense[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [languageIso2, setLanguageIso2] = useState<string>('');
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    setCurrentVisualTourState,
    currentVisualTourState: { run, stepIndex, steps }
  } = useVisualTourContext();

  const [searchParams] = useSearchParams();

  const fullName = useMemo(() => {
    let name = '';
    if (firstName) {
      name += `${firstName} `;
    }
    if (lastName) {
      name += lastName;
    }
    if (!name) {
      name = 'Username';
    }
    return name;
  }, [firstName, lastName]);

  const headerNavItems = (): NavItem[] => {
    const routes = [ROUTES.ORGANIZATION.ORGANIZATION, ROUTES.USERS.USERS];

    const navItems = routes.map((route) => {
      return {
        name: t(route),
        path: getRouteUrl(route)
      };
    });

    const licensesNavItem = {
      name: t(ROUTES.LICENSES.LICENSES),
      path: getRouteUrl(ROUTES.LICENSES.LICENSES)
    };

    if (licenses?.length) {
      navItems.push({
        name: t(ROUTES.LICENSES.LICENSES),
        path: getRouteUrl(ROUTES.LICENSES.LICENSE, {
          licenseId: licenses[0]?.uuid
        })
      });
    } else {
      navItems.push(licensesNavItem);
    }

    return navItems;
  };

  const profileLinks: NavItem[] = [
    {
      name: t('routes.userSettings'),
      path: config.accountFrontendUrl
    }
  ];

  const initValues = () => {
    setFirstName(userData?.firstName);
    setLastName(userData?.lastName);
    setAvatar(userData?.avatar);
    setLanguageIso2(userData?.languageIso2);
  };

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      const status = error.status;
      if (status === 401) {
        redirectToSSOLoginPage();
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    }
  }

  async function fetchData() {
    try {
      const response = await service.fetchData();
      const sidemenuResponse = await sidemenuService.fetchData();

      setUserData(response);
      setLicenses(sidemenuResponse?.licenses);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function autoRunQuickStartGuide() {
    const wasQuickStartGuideShown = getLocalStorageItem(
      LocalStorageKey.wasQuickStartGuideShown
    );

    if (wasQuickStartGuideShown !== wasQuickStartGuideShownValues.yes) {
      const stepsSource = getStepsSource(t); //todo do we need this?
      navigate(getRouteUrl(ROUTES.HOME));
      setCurrentVisualTourState({
        run: true,
        tourActive: true,
        steps: stepsSource
      });

      setLocalStorageItem(
        LocalStorageKey.wasQuickStartGuideShown,
        wasQuickStartGuideShownValues.yes
      );
    }
  }

  useEffect(() => {
    autoRunQuickStartGuide();
  }, [userData]);

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    initValues();
  }, [userData]);

  const userContextData: UserContextData = {
    firstName,
    lastName,
    avatar,
    languageIso2,
    organizations: userData?.organizations || [],
    setFirstName,
    setLastName,
    setAvatar,
    setLanguageIso2
  };

  const handleVisualTourCallback = (data: CallBackProps) => {
    const { action, step, index, type } = data;

    const urlParams = getMergedURLSearchParams(
      searchParams,
      data.step.data?.urlParams
    );

    if (type === 'step:after') {
      switch (action) {
        case 'prev': {
          if (data.step.data?.previous) {
            setCurrentVisualTourState({ run: false, stepIndex: stepIndex - 1 });
            navigate(data.step.data?.previous + urlParams);
          } else {
            setCurrentVisualTourState({ stepIndex: stepIndex - 1 });
          }

          break;
        }
        case 'next': {
          if (data.step.data?.next) {
            setCurrentVisualTourState({
              run: false,
              stepIndex: stepIndex + 1
            });
            navigate(data.step.data?.next + urlParams);
          } else {
            setCurrentVisualTourState({ stepIndex: stepIndex + 1 });
          }

          break;
        }
        case 'close': {
          closeTour();

          break;
        }
      }

      if (stepIndex === steps?.length - 1 && action === 'next') {
        closeTour();
      }
    }
  };

  function closeTour() {
    setCurrentVisualTourState({ run: false, tourActive: false, stepIndex: 0 });
  }

  useEffect(() => {
    if (languageIso2) {
      i18n.changeLanguage(languageIso2);
      try {
        if (getLocalStorageItem(LocalStorageKey.lang) !== languageIso2) {
          setLocalStorageItem(LocalStorageKey.lang, languageIso2);
        }
      } catch (error) {
        logger.error('Local storage error', error);
      }
    }
  }, [languageIso2]);

  return (
    <Fragment>
      <VisualTour
        stepIndex={stepIndex}
        scrollOffset={60}
        runTour={run}
        steps={steps}
        visualTourCallback={handleVisualTourCallback}
        previousText={t('visualTour.arrows.arrowBack')}
        nextText={t('visualTour.arrows.arrowNext')}
      />
      {isAuthChecked && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            navItems={headerNavItems()}
            profileLinks={profileLinks}
            avatar={avatar}
            organizations={userData?.organizations}
            currentOrganizationId={userData?.currentOrganizationId}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main>
            <ScrollToTop />
            <UserContext.Provider value={userContextData}>
              <Outlet />
            </UserContext.Provider>
          </main>
          <NotificationContainer />
        </div>
      )}
      <ScrollTopButton />
    </Fragment>
  );
};

Layout.displayName = 'Layout';
