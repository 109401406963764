import {
  SidemenuContext,
  SidemenuContextData,
  useVisualTourContext
} from '@context';
import {
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  visualTourTimeout
} from '@services';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Organization.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  common,
  ContactPerson,
  CountryIso3,
  Licenses,
  offsets,
  openStatusNotification,
  OrganizationInfo,
  Row,
  SkeletonPlaceholder,
  Typography,
  Users
} from '@xq/ui-kit';
import { OrganizationService } from '@pages/Organizations/Organization/organization-service';
import { OrganizationData } from './dataTypes';
import { getRouteUrl, ROUTES } from '@router';

export const Organization: FC = () => {
  const { t } = useTranslation();
  const service: OrganizationService = new OrganizationService();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState<OrganizationData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  const {
    setCurrentVisualTourState,
    currentVisualTourState: { tourActive, steps }
  } = useVisualTourContext();

  useEffect(() => {
    if (tourActive) {
      if (
        organization &&
        (!organization?.contactPersons || !organization?.contactPersons?.length)
      ) {
        const updatedSteps = steps
          ?.filter((el) => el.id !== 'MAIN_PAGE_CONTACT_PERSON_STEP')
          ?.map((el) => {
            if (el.id === 'MAIN_PAGE_USERS_STEP') {
              el.data = {
                next: getRouteUrl(ROUTES.ORGANIZATION.CONTACT_PERSONS)
              };
            }
            return el;
          });
        setCurrentVisualTourState({ steps: updatedSteps });
      }

      setTimeout(() => {
        setCurrentVisualTourState({ run: true });
      }, visualTourTimeout);
    }
  }, [tourActive, organization]);

  async function fetchData() {
    try {
      setIsLoading(true);
      const response: OrganizationData = await service.fetchData(t);
      setOrganization(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION);
    fetchData();
  }, []);

  const redirectToLicenses = () => {
    if (sidemenuContext?.licenses?.length) {
      navigate(
        getRouteUrl(ROUTES.LICENSES.LICENSE, {
          licenseId: sidemenuContext?.licenses[0]?.uuid
        })
      );
    } else {
      navigate(getRouteUrl(ROUTES.LICENSES.LICENSES));
    }
  };

  const redirectToUsers = () => {
    navigate(getRouteUrl(ROUTES.USERS.USERS));
  };

  const redirectToContactPerson = (contactPersonId: string) => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATION.EDIT_CONTACT_PERSON, {
        contactPersonId: contactPersonId
      })
    );
  };

  /** Sidemenu */
  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION);
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9}>
          <div className={offsets['mb-40']}>
            {!isLoading && (
              <Typography
                element="div"
                variant="h2"
                className={common['no-text-transform']}
              >
                {organization?.organizationInfo?.name}
              </Typography>
            )}
            {isLoading && <SkeletonPlaceholder width={160} height={40} />}
          </div>

          <div className={styles['grid-1']}>
            <Licenses
              id="licenses"
              licenses={organization?.licenses}
              translations={{
                seeDetails: t('uiKit.seeDetails'),
                license: t('common.license'),
                activeUsers: t('organizations.activeUsers'),
                activationDate: t('organizations.activationDate'),
                billingStartDate: t('organizations.billingStartDate'),
                expiryDate: t('uiKit.expiryDate'),
                licenses: t('common.licenses'),
                notDefined: t('common.notDefined'),
                trialVersion: t('uiKit.trialVersion'),
                invoicing: t('organizations.invoicing'),
                paymentMethod: t('organizations.paymentMethod'),
                unlimited: t('uiKit.unlimited')
              }}
              isLoading={isLoading}
              onSeeDetails={redirectToLicenses}
            />
          </div>

          <div className={styles['grid-2']}>
            <OrganizationInfo
              id="organization-info"
              className={styles.info}
              organizationName={organization?.organizationInfo?.name}
              country={
                organization?.organizationInfo?.countryIso3 as CountryIso3
              }
              currency={organization?.organizationInfo?.currencyIso3}
              agreementNumber={organization?.organizationInfo?.agreementId}
              agreementDate={organization?.organizationInfo?.agreementDate}
              translations={{
                organizationInfoText: t('organizations.organizationInfo'),
                organizationText: t('organizations.organization'),
                countryText: t('common.country'),
                currencyText: t('common.currency'),
                agreementNumberText: t('uiKit.agreementNo'),
                agreementDateText: t('organizations.agreementDate'),
                copyText: t('uiKit.copy'),
                copiedText: t('uiKit.сopied'),
                seeDetails: t('uiKit.seeDetails')
              }}
              isLoading={isLoading}
            />

            <Users
              id="organization-users"
              className={styles.users}
              totalUsers={organization?.userStatistic?.totalUsers}
              activeUsers={organization?.userStatistic?.activeUsers}
              administrators={organization?.userStatistic?.administrators}
              externalUsers={organization?.userStatistic?.externalUsers}
              isLoading={isLoading}
              onClick={redirectToUsers}
              isSeeDetails={true}
              translations={{
                totalUsers: t('organizations.totalUsers'),
                activeUsers: t('organizations.activeUsers'),
                administrators: t('organizations.administrators'),
                externalUsers: t('uiKit.externalUsers'),
                users: t('common.users'),
                seeDetails: t('uiKit.seeDetails')
              }}
            />
          </div>

          <div
            className={styles['grid-3']}
            id={'organization-person-visual-tour'}
          >
            {organization?.contactPersons?.length > 0 &&
              organization?.contactPersons?.map((contact, index) => {
                return (
                  <ContactPerson
                    id={index === 0 ? 'organization-person-visual-tour' : null}
                    key={contact?.uuid}
                    role={contact?.position}
                    name={contact?.name}
                    phoneNumber={contact?.phone}
                    email={contact?.email}
                    comments={contact?.comment}
                    isLoading={isLoading}
                    isEdit={false}
                    translations={{
                      seeDetails: t('uiKit.seeDetails'),
                      role: t('uiKit.role'),
                      name: t('common.name'),
                      phoneNumber: 'Phone number',
                      email: t('common.email'),
                      comments: t('uiKit.comments'),
                      delete: t('common.delete'),
                      edit: t('common.edit')
                    }}
                    onSeeDetails={() => redirectToContactPerson(contact?.uuid)}
                  />
                );
              })}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

Organization.displayName = 'Organization';
