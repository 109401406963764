import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoHomeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 90 16" width={90} height={16}>
    <g clipPath="url(#clip0_12559_6590)">
      <path d="M0 0L2.66667 4L5.33333 0H0Z" fill="#FBB040" />
      <path d="M14.666 12L17.3327 8L19.9993 12H14.666Z" fill="#F26522" />
      <path d="M12 0L14.6667 4L17.3333 0H12Z" fill="#EE2A7B" />
      <path d="M9.33398 4L12.0007 0L14.6673 4H9.33398Z" fill="#DA1C5C" />
      <path d="M9.33398 4L12.0007 8L14.6673 4H9.33398Z" fill="#BE1E2D" />
      <path d="M5.33398 8L8.00065 4L10.6673 8H5.33398Z" fill="#F15A29" />
      <path d="M5.33398 8L8.00065 12L10.6673 8H5.33398Z" fill="#EF4136" />
      <path d="M2.66602 12L5.33268 8L7.99935 12H2.66602Z" fill="#BE1E2D" />
      <path d="M14.666 12L17.3327 16L19.9993 12H14.666Z" fill="#F7941D" />
      <path d="M17.334 16L20.0007 12L22.6673 16H17.334Z" fill="#FBB040" />
      <path d="M2.66602 4L5.33268 8L7.99935 4H2.66602Z" fill="#F26522" />
      <path d="M2.66602 4L5.33268 0L7.99935 4H2.66602Z" fill="#F7941D" />
      <path d="M12 8L14.6667 12L17.3333 8H12Z" fill="#F15A29" />
      <path d="M12 8L14.6667 4L17.3333 8H12Z" fill="#EF4136" />
      <path
        d="M27 12.112V2.128H33.24V3.28H28.296V6.32H32.376V7.504H28.296V10.96H33.192V12.112H27Z"
        fill="#121212"
      />
      <path
        d="M34.696 12.112L37.384 7.024L34.744 2.128H36.152L38.12 5.76L40.04 2.128H41.368L38.888 7.008L41.672 12.112H40.2L38.136 8.336L36.12 12.112H34.696Z"
        fill="#121212"
      />
      <path
        d="M48.392 14.432C47.88 14.4533 47.4427 14.4267 47.08 14.352C46.728 14.288 46.44 14.1653 46.216 13.984C46.0027 13.8027 45.8427 13.568 45.736 13.28C45.64 12.992 45.592 12.6347 45.592 12.208C45.0373 12.1227 44.5253 11.8827 44.056 11.488C43.5973 11.0827 43.224 10.5227 42.936 9.808C42.6587 9.08267 42.52 8.20267 42.52 7.168C42.52 6.19733 42.6267 5.38133 42.84 4.72C43.0533 4.05867 43.336 3.53067 43.688 3.136C44.0507 2.74133 44.44 2.45867 44.856 2.288C45.2827 2.10667 45.704 2.016 46.12 2.016C46.6 2.016 47.0587 2.11733 47.496 2.32C47.9333 2.512 48.3173 2.816 48.648 3.232C48.9893 3.648 49.2507 4.18133 49.432 4.832C49.624 5.472 49.7147 6.24 49.704 7.136C49.704 7.88267 49.64 8.54933 49.512 9.136C49.384 9.72267 49.1973 10.2293 48.952 10.656C48.7067 11.072 48.4027 11.408 48.04 11.664C47.6773 11.92 47.2667 12.1013 46.808 12.208C46.8293 12.4213 46.872 12.608 46.936 12.768C47 12.9387 47.1067 13.0667 47.256 13.152C47.4053 13.2373 47.6133 13.28 47.88 13.28L49.192 13.216L49.16 14.432H48.392ZM46.184 11.088C46.4507 11.088 46.712 11.024 46.968 10.896C47.2347 10.7573 47.4747 10.5387 47.688 10.24C47.9013 9.94133 48.072 9.55733 48.2 9.088C48.3387 8.608 48.408 8.02667 48.408 7.344C48.408 6.512 48.3387 5.82933 48.2 5.296C48.0613 4.752 47.8747 4.32533 47.64 4.016C47.416 3.696 47.1707 3.472 46.904 3.344C46.6373 3.216 46.376 3.152 46.12 3.152C45.7467 3.152 45.416 3.25333 45.128 3.456C44.84 3.65867 44.6 3.936 44.408 4.288C44.216 4.62933 44.072 5.02933 43.976 5.488C43.88 5.94667 43.832 6.43733 43.832 6.96C43.832 7.792 43.928 8.51733 44.12 9.136C44.312 9.75467 44.584 10.2347 44.936 10.576C45.288 10.9173 45.704 11.088 46.184 11.088Z"
        fill="#121212"
      />
      <path
        d="M54.12 12.304C53.0427 12.304 52.2213 11.984 51.656 11.344C51.1013 10.6933 50.824 9.79733 50.824 8.656V2.144H52.312V2.272C52.248 2.336 52.2053 2.40533 52.184 2.48C52.1733 2.55467 52.168 2.68267 52.168 2.864V8.672C52.168 9.44 52.312 10.0533 52.6 10.512C52.888 10.96 53.4 11.184 54.136 11.184C54.872 11.184 55.384 10.96 55.672 10.512C55.9707 10.0533 56.12 9.424 56.12 8.624V2.144H57.432V8.608C57.432 9.45067 57.2933 10.144 57.016 10.688C56.7387 11.232 56.3493 11.6373 55.848 11.904C55.3573 12.1707 54.7813 12.304 54.12 12.304Z"
        fill="#121212"
      />
      <path
        d="M63.864 9.392H60.152L60.392 8.384H63.592L63.864 9.392ZM62.088 4.832L59.672 12.112H58.344L61.912 2H62.296L65.864 12.112H64.488L62.088 4.832Z"
        fill="#121212"
      />
      <path
        d="M66.84 2.144H68.184L72.104 9.264L72.072 2.144H73.48V2.272C73.416 2.336 73.3733 2.40533 73.352 2.48C73.3413 2.55467 73.336 2.68267 73.336 2.864V12.112H72.184L68.088 4.56V12.112H66.84V2.144Z"
        fill="#121212"
      />
      <path
        d="M78.632 12.272C78.088 12.272 77.576 12.176 77.096 11.984C76.6267 11.7813 76.2107 11.472 75.848 11.056C75.496 10.64 75.2187 10.112 75.016 9.472C74.8133 8.832 74.712 8.06933 74.712 7.184C74.712 6.40533 74.7867 5.728 74.936 5.152C75.0853 4.56533 75.288 4.07467 75.544 3.68C75.8107 3.27467 76.1093 2.95467 76.44 2.72C76.7813 2.48533 77.1333 2.31467 77.496 2.208C77.8693 2.10133 78.2427 2.048 78.616 2.048C79.352 2.048 79.976 2.224 80.488 2.576C81.0107 2.928 81.4053 3.40267 81.672 4L80.648 4.592L80.504 4.656L80.456 4.544C80.4773 4.45867 80.472 4.37867 80.44 4.304C80.408 4.22933 80.3493 4.11733 80.264 3.968C80.008 3.63733 79.7413 3.41333 79.464 3.296C79.1973 3.17867 78.9147 3.12 78.616 3.12C78.2427 3.12 77.896 3.21067 77.576 3.392C77.256 3.56267 76.9787 3.81867 76.744 4.16C76.5093 4.50133 76.328 4.92267 76.2 5.424C76.0827 5.91467 76.024 6.47467 76.024 7.104C76.024 7.712 76.088 8.26667 76.216 8.768C76.3547 9.25867 76.5413 9.68 76.776 10.032C77.0107 10.384 77.2827 10.656 77.592 10.848C77.912 11.04 78.2533 11.136 78.616 11.136C78.8827 11.136 79.1333 11.0933 79.368 11.008C79.6133 10.9227 79.8373 10.7893 80.04 10.608C80.2427 10.4267 80.4187 10.2133 80.568 9.968L81.528 10.64C81.1973 11.1947 80.7973 11.6053 80.328 11.872C79.8587 12.1387 79.2933 12.272 78.632 12.272Z"
        fill="#121212"
      />
      <path
        d="M83 12.112V2.128H89.24V3.28H84.296V6.32H88.376V7.504H84.296V10.96H89.192V12.112H83Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0_12559_6590">
        <rect width="90" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

LogoHomeIcon.displayName = 'LogoHomeIcon';
