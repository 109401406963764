import { adminApiConfiguration, handleBackendError, mapFiltersFromGatewayToUI, wait } from '@services';
import { User, UsersData, UsersPageData } from '@pages';
import { ListMetaInfo, ListParams, TableFilterValues } from 'interfaces';
import {
  GatewayListFiltersDTOResponse,
  UserListSortOptionsDTOFieldNameEnum,
  UserListSortOptionsDTOOrderEnum,
  UsersListPageApi
} from '@xq/admin-panel-gateway-frontend-client';
import { TableFilter, TableFilterGroup } from '@xq/ui-kit';

export interface UsersService {
  fetchData(): Promise<UsersPageData>;

  fetchFilters(): Promise<TableFilterValues>;

  fetchUsers(params?: ListParams): Promise<UsersData>;

  deleteUser(userUuid: string): Promise<void>;

  blockUser(userUuid: string): Promise<void>;

  unblockUser(userUuid: string): Promise<void>;

  resetPassword(
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void>;
}

const adminUsersGateway = new UsersListPageApi(adminApiConfiguration);

export class UsersServiceApi implements UsersService {
  async fetchUsers(params?: ListParams): Promise<UsersData> {
    try {
      return await adminUsersGateway.userListPageControllerGetUserList({
        searchString: params?.searchValue?.trim(),
        userListOptionsDTORequest: {
          pagination: {
            page: params?.page,
            limit: params?.limit
          },
          filters: params?.filters,
          sort: [
            {
              order: params?.sortOrder as UserListSortOptionsDTOOrderEnum,
              fieldName: params?.sortBy as UserListSortOptionsDTOFieldNameEnum
            }
          ]
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async fetchFilters(): Promise<TableFilterValues> {
    try {
      const filters: GatewayListFiltersDTOResponse =
        await adminUsersGateway.userListPageControllerGetUserListFilters();

      const mappedFilters: TableFilter[] = mapFiltersFromGatewayToUI(
        filters.filters
      );

      const mappedGroups: TableFilterGroup[] = filters.groups?.map((group) => {
        return {
          id: group.groupId,
          groupTitle: group.groupTitle
        };
      });

      return {
        filters: mappedFilters,
        groups: mappedGroups
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async fetchData(): Promise<UsersPageData> {
    try {
      return await adminUsersGateway.userListPageControllerGetPageData({});
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async deleteUser(userUuid: string): Promise<void> {
    try {
      return await adminUsersGateway.userListPageControllerPostDeleteUser({
        userUuid: userUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteUser.name
      );
    }
  }

  async blockUser(userUuid: string): Promise<void> {
    try {
      return await adminUsersGateway.userListPageControllerPostBlockUser({
        userUuid: userUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.blockUser.name
      );
    }
  }

  async unblockUser(userUuid: string): Promise<void> {
    try {
      return await adminUsersGateway.userListPageControllerPostUnblocklockUser({
        userUuid: userUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.unblockUser.name
      );
    }
  }

  async resetPassword(
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void> {
    try {
      await adminUsersGateway.userListPageControllerPostSetPasswordUser({
        userUuid: userUuid,
        userSetPasswordDTORequest: {
          password,
          passwordChangeRequired
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }
}

const usersMock: User[] = [
  {
    uuid: '1',
    avatar: '',
    fullname: 'Charles Hunt',
    email: 'charles.hunt@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: true,
    licenses: ['ModelTree: Investment management'],
    createdAt: new Date('2021-12-19T18:00:00.000Z')
  },
  {
    uuid: '2',
    avatar: '',
    fullname: 'Courtney Scott',
    email: 'courtney.scott@2ns.com',
    isBlocked: false,
    isOrganizationAdmin: false,
    licenses: ['Fundy', 'ModelTree: Investment management'],
    createdAt: new Date('2021-01-22T08:10:10.000Z')
  },
  {
    uuid: '3',
    avatar: '',
    fullname: 'Craig Anderson',
    email: 'craig.anderson@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    licenses: ['Fundy'],
    createdAt: new Date('2011-05-11T08:00:00.000Z')
  }
];

const usersMetaInfoMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 2,
  itemCount: 50
};

export const filterValues: TableFilterValues = {
  filters: [
    {
      id: 'isAdmin',
      type: 'checkbox',
      groupId: 'userRights',
      label: 'Admin',
      value: false
    },
    {
      id: 'isExternal',
      type: 'checkbox',
      groupId: 'userRights',
      label: 'External user',
      value: false
    }
  ],
  groups: [
    {
      id: 'userRights',
      groupTitle: 'User rights'
    }
  ]
};

const usersData: UsersData = {
  items: usersMock,
  meta: usersMetaInfoMock
};

const usersPageDataMock: UsersPageData = {
  statistic: {
    totalUsers: 24,
    activeUsers: 21,
    administrators: 2,
    externalUsers: 1
  }
};

export class UsersServiceMock implements UsersService {
  async fetchUsers(params?: ListParams): Promise<UsersData> {
    try {
      await wait(1000);
      return usersData;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async fetchData(): Promise<UsersPageData> {
    try {
      await wait(1000);
      return usersPageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchFilters(): Promise<TableFilterValues> {
    try {
      await wait(1000);
      return filterValues;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchUsers.name
      );
    }
  }

  async deleteUser(userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteUser.name
      );
    }
  }

  async blockUser(userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.blockUser.name
      );
    }
  }

  async unblockUser(userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.unblockUser.name
      );
    }
  }

  async resetPassword(
    userUuid: string,
    password: string,
    passwordChangeRequired?: boolean
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.resetPassword.name
      );
    }
  }
}
