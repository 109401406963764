(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react"], factory);
	else if(typeof exports === 'object')
		exports["xq-ui-kit"] = factory(require("react"));
	else
		root["xq-ui-kit"] = factory(root["react"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__39155__) {
return 