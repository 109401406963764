import React, { FC } from 'react';
import {
  ComponentProps,
  Icon,
  IconProps,
  Tooltip,
  WarningSidemenuItem
} from '@components';

export interface SidemenuWarningProps extends ComponentProps {
  warning: WarningSidemenuItem;
}

export const SidemenuWarning: FC<SidemenuWarningProps> = (props) => {
  const { id, className, style, dataTestId, warning } = props;

  const iconParams: IconProps = {
    name: warning?.iconName || 'alert',
    color: warning?.color || 'warning',
    size: 's'
  };

  return (
    <div id={id} className={className} style={style} data-testid={dataTestId}>
      {warning?.text ? (
        <Tooltip content={warning.text}>
          <Icon {...iconParams} />
        </Tooltip>
      ) : (
        <Icon {...iconParams} />
      )}
    </div>
  );
};

SidemenuWarning.displayName = 'SidemenuWarning';
