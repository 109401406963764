import { IconNames } from '@components/Icon';

export declare enum HeaderApp {
  Home = 'home',
  Insights = 'insights',
  Administration = 'administration',
  Omni = 'omni'
}

export const HeaderAppNames = Object.freeze({
  Home: 'home' as HeaderApp,
  Insights: 'insights' as HeaderApp,
  Administration: 'administration' as HeaderApp,
  Omni: 'omni' as HeaderApp
});

export declare enum LicenseName {
  Insights = 'insights',
  ModelTree = 'model-tree'
}

export const LicenseNames = Object.freeze({
  Insights: 'insights' as LicenseName,
  ModelTree: 'model-tree' as LicenseName
});

export interface HeaderAppItem {
  name: HeaderApp;
  url: string;
}

export declare type NavItem = {
  name: string;
  path: string;
  onClick?(): void;
  /* needs for profile links to show active link */
  isActive?: boolean;
  /* needs for profile links to open in new tab */
  isOpenInNewTab?: boolean;
  /* needs for mimicry tab */
  alwaysActiveMode?: {
    enabled?: boolean;
    icon?: IconNames;
    buttonAction?: () => void;
  };
};

export declare type OrganizationItem = {
  name: string;
  uuid: string;
};

export interface HeaderTranslations {
  signOut?: string;
  home?: string;
  marketDataAnalysis?: string;
  organizationAdministration?: string;
  superAdministration?: string;
}
