import { ResponseError } from '@xq/iam-frontend-client';
import { BackendErrorResponse, Logger, LogLevel } from '@xq/ui-kit';
import { config } from '@config';
import { getRouteUrl, ROUTES } from '@router';
import { redirectToSSOLoginPage } from '../layouts/Layout/utils';

export const logger = new Logger({ level: config.logLevel as LogLevel });
logger.hideConsoleLogs();

export async function handleBackendError(
  error: ResponseError,
  className: string,
  functionName: string
) {
  const description = `function ${functionName} in class ${className}`;

  const errorBody: BackendErrorResponse = await error?.response?.json();
  if (errorBody) {
    backendErrorLog(errorBody, description);
    handleErrorCode(errorBody);
  } else {
    errorLog(error, description);
  }
  throw errorBody || error;
}

export const backendErrorLog = (
  error: BackendErrorResponse,
  description?: string
) => {
  logger.error(
    `Error ${error.error}, message: ${error.message}, status: ${error.status}${
      description ? ', description: ' + description : ''
    }, service: admin`
  );
};

export const errorLog = (error: Error, description?: string) => {
  logger.error(
    `Error name: ${error.name}, message: ${error.message}${
      description ? ', description: ' + description : ''
    }, service: admin`
  );
};

export const handleErrorCode = (error: BackendErrorResponse) => {
  if (error?.status === 401) {
    redirectToSSOLoginPage();
    return;
  }
  switch (error?.error) {
    case 'IAM_USER_IS_BLOCKED':
    case 'IAM_REQUEST_IP_IS_FORBIDDEN': {
      //todo find how to redirect with react router
      window.location.href = getRouteUrl(ROUTES.ERRORS.FORBIDDEN);
      break;
    }
    case 'NOT_ORGANIZATION_ADMIN': {
      window.location.href = config.accountFrontendUrl;
      break;
    }
    default: {
      break;
    }
  }
};
