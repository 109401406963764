import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './ResetPasswordModal.module.scss';
import { Checkbox, Input, Modal, Typography } from '@components';
import { offsets } from '@componentsStyles';

interface ResetPasswordModalProps {
  t: (value: string) => string;
  isOpen: boolean;
  onClose: () => void;
  userFullName: string;
  resetPassword: () => void;
  newPassword: string;
  setNewPassword: (value: string) => void;
  askUserNextTime: boolean;
  setAskUserNextTime: (value: boolean) => void;
}

export interface PasswordRestriction {
  title: string;
  regex: RegExp;
  isValid: boolean;
}

export const defaultPasswordRestrictions: PasswordRestriction[] = [
  {
    title: 'updatePassword.charactersMinimumRestriction',
    regex: /^.{9,}$/,
    isValid: false
  },
  {
    title: 'updatePassword.charactersMaximumRestriction',
    regex: /^(.{0,60})$/,
    isValid: false
  },
  {
    title: 'updatePassword.lowercaseLetterRestriction',
    regex: /[a-z]/,
    isValid: false
  },
  {
    title: 'updatePassword.uppercaseLetterRestriction',
    regex: /[A-Z]/,
    isValid: false
  },
  {
    title: 'updatePassword.numberRestriction',
    regex: /\d/,
    isValid: false
  },
  {
    title: 'updatePassword.specialCharacterRestriction',
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    isValid: false
  },
  {
    title: 'updatePassword.specialCharacterRestriction',
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    isValid: false
  }
];

export const ResetPasswordModal: FC<ResetPasswordModalProps> = (props) => {
  const {
    t,
    isOpen,
    onClose,
    userFullName,
    resetPassword,
    newPassword,
    setNewPassword,
    askUserNextTime,
    setAskUserNextTime
  } = props;

  const [disabled, setDisabled] = useState(true);
  const [passwordRestrictions, setPasswordRestrictions] = useState<
    PasswordRestriction[]
  >(defaultPasswordRestrictions);

  const close = () => {
    onClose();
    setNewPassword('');
    setAskUserNextTime(false);
    setDisabled(true);
    setPasswordRestrictions(defaultPasswordRestrictions);
  };

  useEffect(() => {
    const updatedRestrictions: PasswordRestriction[] = passwordRestrictions.map(
      (restriction) => {
        const isValid = testRegex(restriction.regex, newPassword);
        return { ...restriction, isValid };
      }
    );
    setPasswordRestrictions(updatedRestrictions);
  }, [newPassword]);

  const isRestrictionsValid: boolean = useMemo(() => {
    return passwordRestrictions?.reduce((result, restriction) => {
      return result && restriction.isValid;
    }, true);
  }, [passwordRestrictions]);

  useEffect(() => {
    const isNotValid: boolean = !newPassword || !isRestrictionsValid;
    setDisabled(isNotValid);
  }, [newPassword, isRestrictionsValid]);

  const testRegex = (regex: RegExp, value: string): boolean => {
    return value && regex.test(value);
  };

  return (
    <Modal
      className={styles.modal}
      title={userFullName && `Reset password for \n ${userFullName}`}
      isOpen={isOpen}
      withCloseButton
      cancelText={'Cancel'}
      submitText={'Reset'}
      isSubmitDisabled={disabled}
      onClose={close}
      onCancel={close}
      onSubmit={resetPassword}
    >
      <div>
        <Input
          value={newPassword}
          onChange={(value) => setNewPassword(String(value))}
          className={offsets['my-20']}
          label={t('organizations.newPassword')}
          required
          type="password"
          withIcon
        />

        <ul className={styles['password-list']}>
          {passwordRestrictions.map((restriction, idx) => {
            return (
              <li
                key={idx}
                className={
                  restriction.isValid
                    ? styles['password-item--valid']
                    : styles['password-item']
                }
              >
                <Typography
                  variant={'body-4'}
                  element={'div'}
                  className={offsets['mb-10']}
                >
                  {t(restriction.title)}
                </Typography>
              </li>
            );
          })}
        </ul>

        <Checkbox
          label={'Ask user to reset password in next sign in'}
          checked={askUserNextTime}
          onChange={setAskUserNextTime}
        />
      </div>
    </Modal>
  );
};

ResetPasswordModal.displayName = 'ResetPasswordModal';
