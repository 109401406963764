import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownAdministrationIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40" width={40} height={40}>
    <rect width="40" height="40" rx="4" fill="#F5F5F5" />
    <g clipPath="url(#clip0_14334_3975)">
      <path d="M20 10L8 30H14L23 15L20 10Z" fill="#F15A24" />
      <path d="M23 15L14 30H20L26 20L23 15Z" fill="#F7931E" />
      <path d="M26 20L29 25L26 30L23 25L26 20Z" fill="#C1272D" />
      <path d="M29 25L32 30H28H26L29 25Z" fill="#ED1C24" />
    </g>
    <defs>
      <clipPath id="clip0_14334_3975">
        <rect width="24" height="20" fill="white" transform="translate(8 10)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

DropdownAdministrationIcon.displayName = 'DropdownAdministrationIcon';
