import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DropdownHomeText: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 63 14" width={63} height={14}>
    <path
      d="M0 10.8924V0.908442H6.24V2.06044H1.296V5.10044H5.376V6.28444H1.296V9.74044H6.192V10.8924H0Z"
      fill="#121212"
    />
    <path
      d="M7.696 10.8924L10.384 5.80444L7.744 0.908442H9.152L11.12 4.54044L13.04 0.908442H14.368L11.888 5.78844L14.672 10.8924H13.2L11.136 7.11644L9.12 10.8924H7.696Z"
      fill="#121212"
    />
    <path
      d="M21.392 13.2124C20.88 13.2338 20.4427 13.2071 20.08 13.1324C19.728 13.0684 19.44 12.9458 19.216 12.7644C19.0027 12.5831 18.8427 12.3484 18.736 12.0604C18.64 11.7724 18.592 11.4151 18.592 10.9884C18.0373 10.9031 17.5253 10.6631 17.056 10.2684C16.5973 9.86311 16.224 9.30311 15.936 8.58844C15.6587 7.86311 15.52 6.98311 15.52 5.94844C15.52 4.97778 15.6267 4.16177 15.84 3.50044C16.0533 2.83911 16.336 2.31111 16.688 1.91644C17.0507 1.52177 17.44 1.23911 17.856 1.06844C18.2827 0.887108 18.704 0.796441 19.12 0.796441C19.6 0.796441 20.0587 0.897774 20.496 1.10044C20.9333 1.29244 21.3173 1.59644 21.648 2.01244C21.9893 2.42844 22.2507 2.96177 22.432 3.61244C22.624 4.25244 22.7147 5.02044 22.704 5.91644C22.704 6.66311 22.64 7.32978 22.512 7.91644C22.384 8.50311 22.1973 9.00978 21.952 9.43644C21.7067 9.85244 21.4027 10.1884 21.04 10.4444C20.6773 10.7004 20.2667 10.8818 19.808 10.9884C19.8293 11.2018 19.872 11.3884 19.936 11.5484C20 11.7191 20.1067 11.8471 20.256 11.9324C20.4053 12.0178 20.6133 12.0604 20.88 12.0604L22.192 11.9964L22.16 13.2124H21.392ZM19.184 9.86844C19.4507 9.86844 19.712 9.80444 19.968 9.67644C20.2347 9.53778 20.4747 9.31911 20.688 9.02044C20.9013 8.72178 21.072 8.33777 21.2 7.86844C21.3387 7.38844 21.408 6.80711 21.408 6.12444C21.408 5.29244 21.3387 4.60977 21.2 4.07644C21.0613 3.53244 20.8747 3.10577 20.64 2.79644C20.416 2.47644 20.1707 2.25244 19.904 2.12444C19.6373 1.99644 19.376 1.93244 19.12 1.93244C18.7467 1.93244 18.416 2.03377 18.128 2.23644C17.84 2.43911 17.6 2.71644 17.408 3.06844C17.216 3.40977 17.072 3.80977 16.976 4.26844C16.88 4.72711 16.832 5.21777 16.832 5.74044C16.832 6.57244 16.928 7.29778 17.12 7.91644C17.312 8.53511 17.584 9.01511 17.936 9.35644C18.288 9.69777 18.704 9.86844 19.184 9.86844Z"
      fill="#121212"
    />
    <path
      d="M27.12 11.0844C26.0427 11.0844 25.2213 10.7644 24.656 10.1244C24.1013 9.47378 23.824 8.57778 23.824 7.43644V0.924441H25.312V1.05244C25.248 1.11644 25.2053 1.18577 25.184 1.26044C25.1733 1.33511 25.168 1.46311 25.168 1.64444V7.45244C25.168 8.22044 25.312 8.83377 25.6 9.29244C25.888 9.74044 26.4 9.96444 27.136 9.96444C27.872 9.96444 28.384 9.74044 28.672 9.29244C28.9707 8.83377 29.12 8.20444 29.12 7.40444V0.924441H30.432V7.38844C30.432 8.23111 30.2933 8.92444 30.016 9.46844C29.7387 10.0124 29.3493 10.4178 28.848 10.6844C28.3573 10.9511 27.7813 11.0844 27.12 11.0844Z"
      fill="#121212"
    />
    <path
      d="M36.864 8.17244H33.152L33.392 7.16444H36.592L36.864 8.17244ZM35.088 3.61244L32.672 10.8924H31.344L34.912 0.780441H35.296L38.864 10.8924H37.488L35.088 3.61244Z"
      fill="#121212"
    />
    <path
      d="M39.84 0.924441H41.184L45.104 8.04444L45.072 0.924441H46.48V1.05244C46.416 1.11644 46.3733 1.18577 46.352 1.26044C46.3413 1.33511 46.336 1.46311 46.336 1.64444V10.8924H45.184L41.088 3.34044V10.8924H39.84V0.924441Z"
      fill="#121212"
    />
    <path
      d="M51.632 11.0524C51.088 11.0524 50.576 10.9564 50.096 10.7644C49.6267 10.5618 49.2107 10.2524 48.848 9.83644C48.496 9.42044 48.2187 8.89244 48.016 8.25244C47.8133 7.61244 47.712 6.84977 47.712 5.96444C47.712 5.18577 47.7867 4.50844 47.936 3.93244C48.0853 3.34577 48.288 2.85511 48.544 2.46044C48.8107 2.05511 49.1093 1.73511 49.44 1.50044C49.7813 1.26577 50.1333 1.09511 50.496 0.988441C50.8693 0.881775 51.2427 0.828442 51.616 0.828442C52.352 0.828442 52.976 1.00444 53.488 1.35644C54.0107 1.70844 54.4053 2.18311 54.672 2.78044L53.648 3.37244L53.504 3.43644L53.456 3.32444C53.4773 3.23911 53.472 3.15911 53.44 3.08444C53.408 3.00977 53.3493 2.89777 53.264 2.74844C53.008 2.41777 52.7413 2.19377 52.464 2.07644C52.1973 1.95911 51.9147 1.90044 51.616 1.90044C51.2427 1.90044 50.896 1.99111 50.576 2.17244C50.256 2.34311 49.9787 2.59911 49.744 2.94044C49.5093 3.28177 49.328 3.70311 49.2 4.20444C49.0827 4.69511 49.024 5.25511 49.024 5.88444C49.024 6.49244 49.088 7.04711 49.216 7.54844C49.3547 8.03911 49.5413 8.46044 49.776 8.81244C50.0107 9.16444 50.2827 9.43644 50.592 9.62844C50.912 9.82044 51.2533 9.91644 51.616 9.91644C51.8827 9.91644 52.1333 9.87378 52.368 9.78844C52.6133 9.70311 52.8373 9.56978 53.04 9.38844C53.2427 9.20711 53.4187 8.99378 53.568 8.74844L54.528 9.42044C54.1973 9.97511 53.7973 10.3858 53.328 10.6524C52.8587 10.9191 52.2933 11.0524 51.632 11.0524Z"
      fill="#121212"
    />
    <path
      d="M56 10.8924V0.908442H62.24V2.06044H57.296V5.10044H61.376V6.28444H57.296V9.74044H62.192V10.8924H56Z"
      fill="#121212"
    />
  </SvgIcon>
);

DropdownHomeText.displayName = 'DropdownHomeText';
