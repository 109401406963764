import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import {
  Avatar,
  ComponentProps,
  Divider,
  HeaderAppItem,
  HeaderTranslations,
  Icon,
  Typography
} from '@components';

import styles from './Header.module.scss';
import {
  HeaderApp,
  NavItem,
  OrganizationItem
} from '@components/Header/dataTypes';
import { AppSwitcher } from './components/AppSwitcher';
import { TabletMenu } from './components/TabletMenu';
import { DesktopMenu } from './components/DesktopMenu';
import classNames from 'classnames';
import { useComponentVisible } from '@componentsUtils';
import { HeaderNavLink } from '@components/Header/components/HeaderNavLink/HeaderNavLink';
import { colors } from '@componentsStyles';

export const activeStyle = {
  color: colors.activeSideMenuItem
};

export interface HeaderProps extends ComponentProps {
  currentApp: HeaderApp;
  navItems?: NavItem[];
  profileLinks: NavItem[];
  organizations: OrganizationItem[];
  currentOrganizationId: string;
  name: string;
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  avatar?: string;
  apps?: HeaderAppItem[];
  translations?: HeaderTranslations;

  onChangeApp?(app: HeaderApp): void;

  onOrganizationChange?(): void;

  onSignOut?(): void;
}

export const Header: FC<HeaderProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    currentApp,
    apps,
    navItems,
    NavLink,
    profileLinks,
    avatar,
    name,
    organizations,
    currentOrganizationId,
    translations = {
      signOut: 'Sign out',
      home: 'Home',
      marketDataAnalysis: 'Market data analysis',
      organizationAdministration: 'Organization administration',
      superAdministration: 'Super administration'
    },
    onOrganizationChange,
    onSignOut
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <header
      className={classNames(styles.wrapper, className)}
      id={id}
      style={style}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <div className={styles.main}>
          <AppSwitcher
            currentApp={currentApp}
            apps={apps}
            translations={translations}
          />
          <nav id="nav-visual-tour" className={styles.links}>
            {navItems?.length > 0 &&
              navItems?.map((item, key) => {
                return (
                  <HeaderNavLink
                    key={key}
                    NavLink={NavLink}
                    navItem={item}
                    onNavItemClick={item?.onClick}
                  />
                );
              })}
          </nav>
        </div>

        <div ref={ref}>
          <div className={styles['menu-block--desktop']}>
            <div
              className={styles['avatar-block']}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              <Avatar image={avatar} name={name} size={'small'} disabled />
              <Typography className={styles.name} variant={'body-5'}>
                {name}
              </Typography>
              <Icon id="drop-down-menu" name={'chevron-down'} size={'s'} />
            </div>
            {isComponentVisible && (
              <DesktopMenu
                profileLinks={profileLinks}
                organizations={organizations}
                currentOrganizationId={currentOrganizationId}
                className={styles['menu-desktop']}
                onOrganizationChange={onOrganizationChange}
                closeMenu={() => setIsComponentVisible(false)}
                NavLink={NavLink}
                onSignOut={onSignOut}
                translations={translations}
              />
            )}
          </div>

          <div className={styles['menu-block--tablet']}>
            <Icon
              onClick={() => setIsComponentVisible(!isComponentVisible)}
              name={isComponentVisible ? 'close' : 'burger'}
              className={
                isComponentVisible ? styles['icon--active'] : styles.icon
              }
              size={'m'}
            />
            {isComponentVisible && (
              <TabletMenu
                navItems={navItems}
                profileLinks={profileLinks}
                organizations={organizations}
                currentOrganizationId={currentOrganizationId}
                avatar={avatar}
                name={name}
                closeMenu={() => setIsComponentVisible(false)}
                onOrganizationChange={onOrganizationChange}
                className={styles['menu-tablet']}
                NavLink={NavLink}
                onSignOut={onSignOut}
                translations={translations}
              />
            )}
          </div>
        </div>
      </div>
      <Divider />
    </header>
  );
};

Header.displayName = 'Header';
