import React, { FC } from 'react';
import { ComponentProps, Icon, SelectItem, Typography } from '@components';
import styles from './Chips.module.scss';
import colors from '@componentsStyles/colors.scss';
import cn from 'classnames';

type RGBType = `rgba(${number},${number},${number},${
  | number
  | `${number}.${number}`})`;

type HEXType = `#${string extends infer T ? T : never}`;

type ColorType = HEXType | RGBType;

export interface ChipsProps extends ComponentProps {
  item: SelectItem;
  onClose: (item: SelectItem) => void;
  disabled?: boolean;
  color?: ColorType;
  bgColor?: ColorType;
  closeIconColor?: ColorType;
}

export const Chips: FC<ChipsProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    disabled,
    item,
    onClose,
    closeIconColor = colors.gray60Color,
    bgColor = colors.white5Color,
    color = colors.gray100Color
  } = props;

  const onCloseClick = (event: React.MouseEvent, item: SelectItem) => {
    event.stopPropagation();
    if (onClose) {
      onClose(item);
    }
  };

  return (
    <div id={id} style={style} className={className} data-testid={dataTestId}>
      <Typography
        variant={'label-1'}
        className={cn(item?.label ? styles.value : styles['value--hidden'], {
          [styles['value--disabled']]: disabled
        })}
        color={color}
        style={{
          backgroundColor: bgColor
        }}
      >
        {item?.label}

        <div
          onClick={(event) => onCloseClick(event, item)}
          className={
            item?.label && !disabled ? styles.close : styles['close--disabled']
          }
          data-testid={'close-chips'}
        >
          <Icon
            name="close"
            size="xs"
            style={{
              fill: closeIconColor
            }}
          />
        </div>
      </Typography>
    </div>
  );
};

Chips.displayName = 'Chips';
