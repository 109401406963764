import React, { createContext, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import { VisualTourStepItemProps } from '@xq/ui-kit';

export interface VisualTourState {
  run: boolean;
  stepIndex: number;
  steps: VisualTourStepItemProps[];
  tourActive: boolean;
  queryParams: URLSearchParams;
}

const visualTourState: VisualTourState = {
  run: true,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  queryParams: new URLSearchParams()
};

export const VisualTourContext = createContext({
  currentVisualTourState: visualTourState,
  setCurrentVisualTourState: () => undefined
});

VisualTourContext.displayName = 'VisualTourContext';

export function VisualTourProvider(props: any) {
  const [currentVisualTourState, setCurrentVisualTourState] =
    useSetState(visualTourState);
  const value = useMemo(
    () => ({
      currentVisualTourState,
      setCurrentVisualTourState
    }),
    [setCurrentVisualTourState, currentVisualTourState]
  );

  return <VisualTourContext.Provider value={value} {...props} />;
}

export function useVisualTourContext(): {
  setCurrentVisualTourState: (
    patch:
      | Partial<VisualTourState>
      | ((previousState: VisualTourState) => Partial<VisualTourState>)
  ) => void;
  currentVisualTourState: VisualTourState;
} {
  const context = useContext(VisualTourContext);

  if (!context) {
    throw new Error('VisualTourContext is missing');
  }

  return context;
}
